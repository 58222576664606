import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';

function WaterRangeSlider({ water, date, onSubmit, disabled }) {
    const [waterAmount, setWaterAmount] = useState(water);

    useEffect(()=> {
        setWaterAmount(water);
    },[water])

    const handleSliderChange = (event) => {
        setWaterAmount(event.target.value);
    }; 

    const handleSubmit = (event) => {
        event.preventDefault();
        if (onSubmit) {
            onSubmit(waterAmount);
        }
    };

    return (
        <Form onSubmit={handleSubmit} className="full-width-form">
            <div className='slider-container'>
                <Form.Group controlId="formWaterAmount" className="form-group-full-width">
                    <Form.Label>Water Intake Amount: {waterAmount} L</Form.Label>
                    <Form.Control
                        type="range"
                        min="0"
                        max="6"
                        step="0.5"
                        value={waterAmount}
                        onChange={handleSliderChange}
                        disabled={disabled}
                    />
                </Form.Group>
                <div className="button-container">
                    <Button variant="primary" type="submit" disabled={disabled}>
                        Add Water
                    </Button> 
                </div>
            </div>
        </Form>
    );
}

export default WaterRangeSlider;