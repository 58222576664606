import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import "../styles/additionalAppointment.css";  
import { useAuth } from "../providers";
import toast from 'react-hot-toast';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the datepicker CSS

function App2() {
  const auth = useAuth();
  const [selectedTab, setSelectedTab] = useState(null);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [upcomingAppointmentTab, setUpcomingAppointmentTab] = useState(false);

  const [upcomingAppointmentDetails, setUpcomingAppointmentDetails] = useState({
    date: null,
    location: "",
    doctorName: "",
    comments: "",
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    fetchRecordData('upcomingAppointments');
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const mm = date.getMonth() + 1; 
    const dd = date.getDate();
    const yyyy = date.getFullYear();

    return `${mm}-${dd}-${yyyy}`;
}

  const fetchRecordData = async (endpoint) => {
    const userId = auth.user?.id;
    console.log('userId', userId);
    const data = await fetch(
      `https://medharbor-backend.onrender.com/${endpoint}/${userId}`
    ); // Update with the deployed URL
    const res = await data.json();
    try {
      if (res && res.length > 0) {
        const formattedData = res.map(item => ({
          ...item,
          date: formatDate(item.date)
        }))
        setUpcomingAppointments(formattedData);
        console.log('new fetched data ', res);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab === selectedTab ? null : tab);
  };

  const styles = {
    margin: 10,
    paddingTop: 15,
  };

  const openUpcomingAppointmentModal = () => {
      setUpcomingAppointmentDetails({
          date: null,
          location: '',
          doctorName: '',
          comments: '',
      });
      setErrors({});
      setTouched({});
      setUpcomingAppointmentTab(true);
  };

  const closeUpcomingAppointmentModal = () => {
    setUpcomingAppointmentTab(false);
  };

  const handleBlur = (event, fieldName) => {
    const { value } = event.target;
    validateField(fieldName, value);
  };

  const handleDateChange = (date) => {
    setUpcomingAppointmentDetails({ ...upcomingAppointmentDetails, date });
    validateField('date', date);
  };

  const validateField = (fieldName, value) => {
    let newErrors = { ...errors };
    let isTouched = { ...touched };
    isTouched[fieldName] = true;

    switch (fieldName) {
      case 'date':
        if (!value) {
          newErrors.date = 'Date is required';
        } else {
          delete newErrors.date;
        }
        break;
      case 'location':
        if (!value) {
          newErrors.location = 'Location is required';
        } else {
          delete newErrors.location;
        }
        break;
      case 'doctorName':
        if (!value) {
          newErrors.doctorName = 'Doctor name is required';
        } else {
          delete newErrors.doctorName;
        }
        break;
      case 'comments':
        if (!value) {
          newErrors.comments = 'Comments are required';
        } else {
          delete newErrors.comments;
        }
        break;
      default:
        break;
    }

    setTouched(isTouched);
    setErrors({ ...newErrors });
  };

  const allFieldsTouched = () => {
    return touched.date && touched.location && touched.doctorName && touched.comments;
  };

  const isDisabled = () => {
    if (Object.keys(errors).length > 0) return true;
    if (!allFieldsTouched()) {
      return true;
    }
    return false;
  };

  const handleUpcomingAppointmentInputChange = (e) => {
    const { name, value } = e.target;
    setUpcomingAppointmentDetails({
      ...upcomingAppointmentDetails,
      [name]: value,
    });
    validateField(name, value);
  };

  const addUpcomingAppointmentDetails = () => {
    postFormData(upcomingAppointmentDetails, 'upcomingAppointments');
    setUpcomingAppointmentTab(false);
  };

  const postFormData = async (data, endpoint) => {
    const newData = { ...data, userId: auth.user?.id };
    const url = `https://medharbor-backend.onrender.com/${endpoint}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newData),
    });
    try {
      if (!res.ok) {
        throw new Error('Failed to post data');
      }
      console.log('Data posted successfully', res);
      const newAppointment = await res.json();
      console.log('New Upcoming Appointment', newAppointment);
      fetchRecordData('upcomingAppointments')
      toast.success('Upcoming appointment added successfully!');
    } catch (error) {
      console.error('Error posting data:', error.message);
      toast.error(error.message);
    }
  };

  return (
      <div className="trail-container1">
          <ul style={{ paddingLeft: '0px' }}>
              <>
                  <div className="details-container1">
                      <table className="appointment-table1">
                          <thead>
                              <tr>
                                  <th>Date</th>
                                  <th>Location</th>
                                  <th>Doctor Name</th>
                                  <th>Comments</th>
                              </tr>
                          </thead>
                          <tbody>
                              {upcomingAppointments.map((appointment) => (
                                  <tr
                                      key={`${appointment.doctorName}-${appointment.location}-${appointment.comments}`}
                                  >
                                      <td>{appointment.date}</td>
                                      <td>{appointment.location}</td>
                                      <td>{appointment.doctorName}</td>
                                      <td>{appointment.comments}</td>
                                  </tr>
                              ))}
                          </tbody>
                      </table>
                  </div>
                  <div>
                      <Button
                          className="btn btn-md"
                          style={styles}
                          onClick={openUpcomingAppointmentModal}
                      >
                          Add Upcoming Appointment
                      </Button>

                      <Modal
                          show={upcomingAppointmentTab}
                          onHide={closeUpcomingAppointmentModal}
                      >
                          <Modal.Header closeButton>
                              <Modal.Title>
                                  Add Upcoming Appointment
                              </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                              <Form>
                                  <Form.Group style={{ marginTop: 10 }}>
                                      <Form.Label>Date</Form.Label>
                                      <DatePicker
                                          className={`form-control ${
                                              errors.date ? 'is-invalid' : ''
                                          }`}
                                          selected={
                                              upcomingAppointmentDetails.date
                                          }
                                          onChange={handleDateChange}
                                          onBlur={() =>
                                              validateField('date', upcomingAppointmentDetails.date)
                                          }
                                          placeholderText="Select a Date..."
                                          dateFormat="MM/dd/yyyy"
                                      />

                                      {errors.date && (
                                          <div
                                              className="invalid-feedback"
                                              style={{ display: 'block' }}
                                          >
                                              {errors.date}
                                          </div>
                                      )}
                                  </Form.Group>
                                  <Form.Group style={{ marginTop: 30 }}>
                                      <Form.Label>Location</Form.Label>
                                      <Form.Control
                                          type="text"
                                          name="location"
                                          value={
                                              upcomingAppointmentDetails.location
                                          }
                                          onChange={
                                              handleUpcomingAppointmentInputChange
                                          }
                                          onBlur={(e) =>
                                              handleBlur(e, 'location')
                                          }
                                          placeholder="Add the Location Name"
                                          isInvalid={!!errors.location}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                          {errors.location}
                                      </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group style={{ marginTop: 30 }}>
                                      <Form.Label>Doctor Name</Form.Label>
                                      <Form.Control
                                          type="text"
                                          name="doctorName"
                                          value={
                                              upcomingAppointmentDetails.doctorName
                                          }
                                          onChange={
                                              handleUpcomingAppointmentInputChange
                                          }
                                          onBlur={(e) =>
                                              handleBlur(e, 'doctorName')
                                          }
                                          placeholder="Mention the name of Doctor"
                                          isInvalid={!!errors.doctorName}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                          {errors.doctorName}
                                      </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group style={{ marginTop: 30 }}>
                                      <Form.Label>Comments</Form.Label>
                                      <Form.Control
                                          type="text"
                                          name="comments"
                                          value={
                                              upcomingAppointmentDetails.comments
                                          }
                                          onChange={
                                              handleUpcomingAppointmentInputChange
                                          }
                                          onBlur={(e) =>
                                              handleBlur(e, 'comments')
                                          }
                                          placeholder="Add Comments"
                                          isInvalid={!!errors.comments}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                          {errors.comments}
                                      </Form.Control.Feedback>
                                  </Form.Group>
                              </Form>
                          </Modal.Body>
                          <Modal.Footer>
                              <Button onClick={closeUpcomingAppointmentModal}>
                                  Cancel
                              </Button>
                              <Button
                                  onClick={addUpcomingAppointmentDetails}
                                  disabled={isDisabled()}
                              >
                                  Add
                              </Button>
                          </Modal.Footer>
                      </Modal>
                  </div>
              </>
          </ul>
      </div>
  );
}

export default App2;