export const setItemInLocalStorage = (key, value) => {
  console.log('key', key);
  console.log('value', value);
  if (!key || !value) {
    console.error("Can't store item in local stroage");
    return;
  }

  let valueToStore = typeof value !== 'string' ? JSON.stringify(value) : value;

  localStorage.setItem(key, valueToStore);
};

export const removeItemFromLocalStorage = (key) => {
  if (!key) {
    console.error("Can't remove item in local stroage");
    return;
  }

  localStorage.removeItem(key);
};

export const getItemFromLocalStorage = (key) => {
  if (!key) {
    console.error("Can't remove item in local stroage");
    return;
  }

  return localStorage.getItem(key);
};
