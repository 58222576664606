import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';

function CaloriesConsumeProgressBar({ meals=[], goalCalories = 0 }) {
    const totalCaloriesConsumed = meals.length> 0 ? meals.reduce((total, meal) => total + meal.calories, 0): 0;
    const progress = goalCalories>0 ? (totalCaloriesConsumed / goalCalories) * 100 : 0;
    const progressText = totalCaloriesConsumed > goalCalories
        ? `You have exceeded the goal by ${totalCaloriesConsumed - goalCalories} Cal`
        : `You need ${goalCalories - totalCaloriesConsumed} Cal more to reach the goal`;

    return (
        <div>
            <h5 className="caloriesconsume">Calories Consumed Progress</h5>
            <ProgressBar now={progress} label={`${progress.toFixed(0)}%`} />
            <p>{progressText}</p>
        </div>
    );
}

export default CaloriesConsumeProgressBar;
