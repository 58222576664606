import { TextInput, rem } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';
import { Spinner, Button, Modal, Form } from 'react-bootstrap';
import '../styles/MyRecords.css';
import LoginPopup from '../components/LoginPopup';
import { useAuth } from '../providers';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import toast from 'react-hot-toast';

import Alert from 'react-bootstrap/Alert';
import DatePicker from 'react-datepicker';
import { parseISO, format } from 'date-fns';

function App() {
    const auth = useAuth();
    const [search, setSearch] = useState('');
    const [selectedTab, setSelectedTab] = useState('previous');
    const [previousAppointments, setPreviousAppointments] = useState([]);
    const [filteredPreviousAppointments, setFilteredPreviousAppointments] =
        useState([]);
    const [upcomingAppointments, setUpcomingAppointments] = useState([]);
    const [filteredUpcomingAppointments, setFilteredUpcomingAppointments] =
        useState([]);
    const [billingHistory, setBillingHistory] = useState([]);
    const [filteredBillingHistory, setFilteredBillingHistory] = 
        useState([]);
    const [insurance, setInsurance] = useState([]);
    const [filteredInsurance, setFilteredInsurance] = 
        useState([]);
    const [allRecords, setAllRecords] = useState([]);
    const [filteredAllRecords, setFilteredAllRecords] = 
        useState([]);
    const [medicalRecords, setMedicalRecords] = useState([]);
    const [filteredMedicalRecords, setFilteredMedicalRecords] = 
        useState([]);

    const [loading, setLoading] = useState(true);
    const [addType, setAddType] = useState('');
    const [addResult, setAddResult] = useState('');

    const [previousAppointmentTab, setPreviousAppointmentTab] = useState(false);
    const [upcomingAppointmentTab, setUpcomingAppointmentTab] = useState(false);
    const [showAddAllRecordsError, setShowAddAllRecordsError] = useState(false);
    const [addRecordDate, setAddRecordDate] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [action, setAction] = useState(null);
    const [record, setRecord] = useState(null);
    const [deleteUrl, setDeleteUrl] = useState(null);
    const [previousAppointmentDetails, setPreviousAppointmentDetails] =
        useState({
            date: '',
            location: '',
            doctorName: '',
            comments: '',
        });

    const [upcomingAppointmentDetails, setUpcomingAppointmentDetails] =
        useState({
            date: '',
            location: '',
            doctorName: '',
            phoneNumber: '',
            comments: '',
        });

    const [billingHistoryDetails, setBillingHistoryDetails] = useState({
        date: '',
        description: '',
        amount: '',
    });
    const [insuranceDetails, setInsuranceDetails] = useState({
        validity: '',
        provider: '',
        policyNumber: '',
    });

    const [allRecordsDetails, setAllRecordsDetails] = useState({
        date: '',
        type: '',
        result: '',
    });

    const [medicalRecordsDetails, setMedicalRecordsDetails] = useState({
        uploadImage: '',
    });

    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    useEffect(() => {
        // Calling APIs for all the tabs to load data for each one of them
        fetchRecordData('previousAppointments');
        fetchRecordData('upcomingAppointments');
        fetchRecordData('billingHistory');
        fetchRecordData('insurances');
        fetchRecordData('medicalRecords');
        fetchRecordData('allrecords');
    }, [auth.user?.id]);

    const handleTabClick = (tab) => {
        setSelectedTab(tab === selectedTab ? null : tab);
        if (tab === 'previous' && (!previousAppointments ||  previousAppointments.length === 0)) {
            fetchRecordData('previousAppointments');
        } else if (tab === 'upcoming' && (!upcomingAppointments || upcomingAppointments.length === 0)) {
            fetchRecordData('upcomingAppointments');
        } else if (tab === 'billing' && (!billingHistory || billingHistory.length === 0)) {
            fetchRecordData('billingHistory');
        } else if (tab === 'ins' && (!insurance || insurance.length === 0)) {
            fetchRecordData('insurances');
        } else if (tab === 'Medical' && (!medicalRecords || medicalRecords.length === 0)) {
            fetchRecordData('medicalRecords');
        } else if (tab === 'all' && (!allRecords || allRecords.length === 0)) {
            fetchRecordData('allrecords');
        }
    };

    const fetchRecordData = async (endpoint) => {
        setLoading(false);
        const userId = auth.user?.id;
        const data = await fetch(`https://medharbor-backend.onrender.com/${endpoint}/${userId}`);
        // console.log(`https://medharbor-backend.onrender.com/${endpoint}/${userId}`);
        const response = await data.json();
        handleApiResponse(endpoint, response);
        try {
            if (!response || response === undefined || !data.ok) {
                setLoading(false);
                throw new Error('Failed to load data');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error.message);
        }
    };

    const handleApiResponse = (endpoint, res) => {
        setLoading(false);
        if (endpoint.includes('previous')) {
            setPreviousAppointments(res);
            setFilteredPreviousAppointments(res);
        } else if (endpoint.includes('upcoming')) {
            setUpcomingAppointments(res);
            setFilteredUpcomingAppointments(res);
        } else if (endpoint.includes('billing')) {
            setBillingHistory(res);
            setFilteredBillingHistory(res);
        } else if (endpoint.includes('medical')) {
            setMedicalRecords(res);
            setFilteredMedicalRecords(res);
        } else if (endpoint.includes('insurances')) {
            setInsurance(res);
            setFilteredInsurance(res);
        } else if (endpoint.includes('allrecords')) {
            setAllRecords(res);
            setFilteredAllRecords(res);
        }
    };

    const styles = {
        margin: 10,
        padding: 10,};

    const openPreviousAppointmentModal = (action, record) => {
        console.log('selECTED RECORD ', record);
        const recordId = record && record._id ? record._id : null;
        setEditedAppointmentId(recordId);
        setPreviousAppointmentTab(true);
        setShow(true);
        setErrors({});
        setTouched({});
        setAction(action);
        setRecord(record);
        record
            ? setPreviousAppointmentDetails({
                  date: record.date,
                  location: record.location,
                  doctorName: record.doctorName,
                  comments: record.comments,
              })
            : setPreviousAppointmentDetails({
                  date: '',
                  location: '',
                  doctorName: '',
                  comments: '',
              });
    };

    const openUpcomingAppointmentModal = (action, record) => {
        setUpcomingAppointmentTab(true);
        setAction(action);
        setRecord(record);
        setErrors({});
        setTouched({});
        record
            ? setUpcomingAppointmentDetails({
                  date: new Date(record.date),
                  location: record.location,
                  doctorName: record.doctorName,
                  phoneNumber: record.phoneNumber,
                  comments: record.comments,
              })
            : setUpcomingAppointmentDetails({
                  date: new Date(),
                  location: '',
                  doctorName: '',
                  phoneNumber: '',
                  comments: '',
              });
    };

    const openDeleteModal = (record, endpoint) => {
        setDeleteModal(true);
        setRecord(record);
        setDeleteUrl(endpoint);
    };

    const closeDeleteModal = () => {
        setDeleteModal(false);
    };

    const deleteRecord = async () => {
        const recordId = record && record._id ? record._id.toString() : null;
        const urlApi = 'https://medharbor-backend.onrender.com';
        if (deleteUrl === '/previousAppointments') {
            const res = await fetch(
                `${urlApi}/previousAppointments/${recordId}`,
                {
                    method: 'DELETE',
                }
            );
            const filteredRecords = previousAppointments.filter(
                (record) => record._id !== recordId
            );
            setPreviousAppointments(filteredRecords);
            setFilteredPreviousAppointments(filteredRecords);
            setDeleteModal(false);
        } else if (deleteUrl === '/upcomingAppointments') {
            const res = await fetch(
                `${urlApi}/upcomingAppointments/${recordId}`,
                {
                    method: 'DELETE',
                }
            );
            const filteredRecords = upcomingAppointments.filter(
                (record) => record._id !== recordId
            );
            setUpcomingAppointments(filteredRecords);
            setFilteredUpcomingAppointments(filteredRecords);
            setDeleteModal(false);
        } else if (deleteUrl === '/insurances') {
            const res = await fetch(`${urlApi}/insurances/${recordId}`, {
                method: 'DELETE',
            });
            const filteredRecords = insurance.filter(
                (record) => record._id !== recordId
            );
            setInsurance(filteredRecords);
            setFilteredInsurance(filteredRecords);
            setDeleteModal(false);
        } else if (deleteUrl === '/billingHistory') {
            const res = await fetch(`${urlApi}/billingHistory/${recordId}`, {
                method: 'DELETE',
            });
            const filteredRecords = billingHistory.filter(
                (record) => record._id !== recordId
            );
            setBillingHistory(filteredRecords);
            setFilteredBillingHistory(filteredRecords);
            setDeleteModal(false);
        } else if (deleteUrl === '/allrecords') {
            const res = await fetch(`${urlApi}/allrecords/${recordId}`, {
                method: 'DELETE',
            });
            const filteredRecords = allRecords.filter(
                (record) => record._id !== recordId
            );
            setAllRecords(filteredRecords);
            setFilteredAllRecords(filteredRecords);
        }
    };

    const closeUpcomingAppointmentModal = () => {
        setUpcomingAppointmentTab(false);
    };

    const postFormData = async (data, endpoint) => {
        const newData = { ...data, userId: auth.user?.id };
        const url = `https://medharbor-backend.onrender.com/${endpoint}`;
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newData),
        });
        try {
            if (!res.ok) {
                throw new Error('Failed to post data');

            }
            const newPrevAppointment = await res.json();
            fetchRecordData(endpoint);
            toast.success('Upcoming Appointment Posted Succesfully!')
            if(search && search.length > 0){
                setSearch('')
            }
            // Update local state to include the newly added appointment
            updateTable(endpoint, newPrevAppointment);
        } catch (error) {
            console.error(
                'Error posting previous appointment data:',
                error.message
            );
        }
    };

    const updateFormData = async (data, endpoint) => {
        const urlApi = 'https://medharbor-backend.onrender.com';
        const recordId = record && record._id ? record._id.toString() : null;
        const queryParams = new URLSearchParams({
            _id: recordId,
            // Add more query parameters as needed
        });
        const url = `${urlApi}/${endpoint}/${recordId.toString()}`;

        const res = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        try {
            if (!res.ok) {
                throw new Error('Failed to update form');
            }
            const newPrevAppointment = await res.json();
            fetchRecordData(endpoint);
        } catch (error) {
            console.error(
                'Error posting previous appointment data:',
                error.message
            );
        }
    };

    const updateTable = (endpoint, newData) => {
        switch (endpoint) {
            case 'previousAppointments':
                setPreviousAppointments([...previousAppointments, newData]);
                setPreviousAppointmentDetails({
                    date: new Date(),
                    location: '',
                    doctorName: '',
                    comments: '',
                });
                break;
            case 'upcomingAppointments':
                setUpcomingAppointments([...upcomingAppointments, newData]);
                setUpcomingAppointmentDetails({
                    date: new Date(),
                    location: '',
                    doctorName: '',
                    phoneNumber: '',
                    comments: '',
                });
                break;
            case 'billingHistory':
                setBillingHistory([...billingHistory, newData]);
                setBillingHistoryDetails({
                    data: '',
                    description: '',
                    amount: '',
                });
                break;
            case 'insurances':
                setInsurance([...insurance, newData]);
                setInsuranceDetails({
                    validity: '',
                    provider: '',
                    policyNumber: '',
                });
                break;
            case 'allRecords':
                setAllRecords([...allRecords, newData]);
                setAllRecordsDetails({
                    Date: '',
                    type: '',
                    result: '',
                    creator: '',
                });
                break;
            default:
                setMedicalRecords([...medicalRecords, newData]);
                setMedicalRecordsDetails({
                    uploadImage: '',
                });
                break;
        }
    };

    const handlePreviousAppointmentInputChange = (event) => {
        const { name, value } = event.target;
        const updatedDetails = { ...previousAppointmentDetails, [name]: value };
        setPreviousAppointmentDetails(updatedDetails);
        validateField(name, value);
    };

    const handleUpcomingAppointmentInputChange = (event) => {
        const { name, value } = event.target;
        const updatedDetails = { ...upcomingAppointmentDetails, [name]: value };
        setUpcomingAppointmentDetails(updatedDetails);
        validateField(name, value);
    };

    const isDisabled = () => {
        if (Object.keys(errors).length > 0) return true;
        if (action === 'Add' && !allFieldsTouched()) {
            return true;
        }

        return false;
    };

    const handleBlur = (event, fieldName) => {
        const { value } = event.target;
        validateField(fieldName, value);
    };

    const handleDateChange = (date) => {
        const updatedDetails = { ...upcomingAppointmentDetails, date };
        setUpcomingAppointmentDetails(updatedDetails);
        validateField('date', date);
        console.log(errors);
    };

    const validateField = (fieldName, value) => {
        let newErrors = { ...errors };
        let isTouched = { ...touched };
        isTouched[fieldName] = true;
        switch (fieldName) {
            case 'date':
                if (!value) {
                    newErrors.date = 'Date is required';
                } else {
                    delete newErrors.date;
                }
                break;
            case 'location':
                if (!value) {
                    newErrors.location = 'Location is required';
                } else {
                    delete newErrors.location;
                }
                break;
            case 'doctorName':
                if (!value) {
                    newErrors.doctorName = 'Doctor name is required';
                } else {
                    delete newErrors.doctorName;
                }
                break;
            case 'comments':
                if (!value) {
                    newErrors.comments = 'Comments are required';
                } else {
                    delete newErrors.comments;
                }
                break;
            default:
                break;
        }
        setTouched(isTouched);
        setErrors({ ...newErrors });
    };

    const allFieldsTouched = () => {
        return touched.location && touched.doctorName && touched.comments;
    };

    const addUpcomingAppointmentDetails = () => {
        // setUpcomingAppointments([...upcomingAppointments, upcomingAppointmentDetails])
        setUpcomingAppointmentTab(false);
        action === 'Add'
            ? postFormData(upcomingAppointmentDetails, 'upcomingAppointments')
            : updateFormData(
                  upcomingAppointmentDetails,
                  'upcomingAppointments'
              );
    };

    const [addLocation, setAddLocation] = useState('');
    const [addDoctorName, setAddDoctorName] = useState('');
    const [addComments, setAddComments] = useState('');
    const [addPhoneNumber, setAddPhoneNumber] = useState('');
    const [addDate, setAddDate] = useState('');

    const [addProvider, setAddProvider] = useState('');
    const [addPolicyNumber, setAddPolicyNumber] = useState('');
    const [addValidityDate, setAddValidityDate] = useState('');
    const [showAddInsuranceError, setShowAddInsuranceError] = useState(false);
    const [addDescription, setAddDescription] = useState('');
    const [addAmount, setAddAmount] = useState('');
    const [showAddBillingHistoryError, setShowAddBillingHistoryError] =
        useState(false);
    const [
        showAddPreviousAppointmentError,
        setShowAddPreviousAppointmentError,
    ] = useState(false);

    const [modalMode, setModalMode] = useState('add');
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        clearForm();
    }
    const [sortedData, setSortedData] = useState('');
    const [previousAppointmentData, setPreviousAppointmentData] = useState([]);

    const [AllRecordsData, setAllRecordsData] = useState([]);

    const [insuranceData, setInsuranceData] = useState([]);
    const [editedAppointmentId, setEditedAppointmentId] = useState(null);
    const [editedRecordId, setEditedRecordId] = useState(null);

    // Clear All the Modal forms
    const clearForm = () => {
        // For Previous Appointments tab
        setShowAddPreviousAppointmentError(false);
        setAddLocation('');
        setAddDate(''); // Common for Previous, Upcoming, and Billing tabs
        // Common for Previous and Upcoming Appointments tabs
        setAddDoctorName('');
        setAddPhoneNumber('');
        setAddComments('');
        // For Insurance Records tab
        setAddPolicyNumber('');
        setAddProvider('');
        setAddValidityDate('');
        // For Billing Records tab
        setAddAmount('');
        setAddDescription('');
        setShowAddBillingHistoryError(false);
        // For Medical Records tab
        setSelectedFile(false);
        setShowMedicalRecordForm(false);
        setModalMode('add');
        // For All Records section
        setAddRecordDate('');
        setAddType('');
        setAddResult('');
    };

    // previous appointment save for edit and add prev appointment
    const handleSave = () => {
        if (action.toLowerCase() === 'edit') {
            handleEditSubmit();
        } else {
            addPreviousAppointment();
        }
    };

    const handleSaveAll = () => {
        if (modalMode === 'edit') {
            handleEditAllSubmit();
        } else {
            addAllRecords();
        }
    };
    const handleEditAll = (allRecords) => {
        setModalMode('edit');
        setEditedRecordId(allRecords._id);
        // setAddDate(parseISO(allRecords.date));
        setAddRecordDate(parseISO(allRecords.date));
        setAddType(allRecords.type);
        setAddResult(allRecords.result);

        // Show the modal dialog
        setShow(true);
    };
    const handleEditAllSubmit = async () => {
        if (!addRecordDate || !addType || !addResult) {
            setShowAddAllRecordsError(true);
            return;
        }

        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: editedRecordId,
                type: addType,
                date: addRecordDate,
                result: addResult,
            }),
        };

        const response = await fetch(
            `https://medharbor-backend.onrender.com/allrecords/${editedRecordId}`,
            requestOptions
        );
        const data = await response.json();

        // Close the modal after successful update
        handleClose();
        toast.success('Record Updated Successfully!'); // Display message in an alert box

        fetchRecordData('allrecords');
    };
    const addAllRecords = async () => {
        setModalMode('add');

        if (!addRecordDate || !addType || !addResult) {
            setShowAddAllRecordsError(true);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                creator: auth.user?.id,
                date: addRecordDate,
                type: addType,
                result: addResult,
            }),
        };

        const response = await fetch(
            'https://medharbor-backend.onrender.com/allrecords',
            requestOptions
        );

        const data = await response.json();

        console.log('new all records', data);

        fetchRecordData('allrecords');

        setSortedData([
            {
                date: addRecordDate,
                type: addType,
                result: addResult,
            },
            ...sortedData,
        ]);

        handleClose();

        toast.success('All Records Added Successfully!'); // Display message in an alert box
    };

    const addPreviousAppointment = async () => {
        setModalMode('add');
        console.log(previousAppointmentDetails);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: auth.user.id,
                location: previousAppointmentDetails.location,
                doctorName: previousAppointmentDetails.doctorName,
                comments: previousAppointmentDetails.comments,
                date: previousAppointmentDetails.date
                    ? previousAppointmentDetails.date
                    : new Date().toISOString(),
            }),
        };

        const response = await fetch(
            'https://medharbor-backend.onrender.com/previousAppointments',
            requestOptions
        );

        const data = await response.json();

        setShow(false);
        fetchRecordData('previousAppointments');

        setSortedData([
            {
                location: addLocation,
                comments: addComments,
                doctorName: addDoctorName,
                date: addDate,
            },
            ...sortedData,
        ]);

        handleClose();
        if (search && search.length > 0) {
            setSearch('');
        }
        toast.success('Previous Appointment Added Successfully!');
    };

    const handleEditSubmit = async () => {
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: editedAppointmentId,
                location: previousAppointmentDetails.location,
                date: previousAppointmentDetails.date
                    ? new Date(previousAppointmentDetails.date).toISOString()
                    : new Date().toISOString(),
                doctorName: previousAppointmentDetails.doctorName,
                comments: previousAppointmentDetails.comments,
            }),
        };
        const response = await fetch(
            `https://medharbor-backend.onrender.com/previousAppointments/${editedAppointmentId}`,
            requestOptions
        );
        const data = await response.json();

        handleClose();
        toast.success('Previous Appointment Updated Successfully!'); // Display message in an alert box
        fetchRecordData('previousAppointments');
    };

    const deletePreviousAppointment = async (appointmentId) => {
        // Send a request to delete the record from the database
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: appointmentId,
            }),
        };

        const response = await fetch(
            `https://medharbor-backend.onrender.com/previousAppointments/${appointmentId}`,
            requestOptions
        );

        // Update the UI by removing the deleted record from medicationsData
        setPreviousAppointmentData((prevData) =>
            prevData.filter((item) => item._id !== appointmentId)
        );

        // Optionally, display a confirmation message
        toast.success('Appoitnment Deleted Successfully!');

        fetchRecordData('previousAppointments');
    };
    const deleteAllRecords = async (recordId) => {
        // Send a request to delete the record from the database
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: recordId,
            }),
        };

        const response = await fetch(
            `https://medharbor-backend.onrender.com/allrecords/${recordId}`,
            requestOptions
        );

        // Update the UI by removing the deleted record from medicationsData
        setAllRecordsData((allData) =>
            allData.filter((item) => item._id !== recordId)
        );

        // Optionally, display a confirmation message
        toast.success('Record Deleted Successfully');

        fetchRecordData('allrecords');
    };

    const handleInsuranceSave = () => {
        if (modalMode === 'edit') {
            handleInsuranceEditSubmit();
        } else {
            addInsurance();
        }
    };

    const addInsurance = async () => {
        setModalMode('add');

        if (!addValidityDate || !addProvider || !addPolicyNumber) {
            setShowAddInsuranceError(true);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                creator: auth.user.id,
                provider: addProvider,
                policyNumber: addPolicyNumber,
                validityDate: addValidityDate,
            }),
        };

        const response = await fetch(
            `https://medharbor-backend.onrender.com/insurances`,
            requestOptions
        );

        const data = await response.json();

        fetchRecordData('insurances');

        setSortedData([
            {
                provider: addProvider,
                policyNumber: addPolicyNumber,
                validityDate: addValidityDate,
            },
            ...sortedData,
        ]);

        handleClose();

        toast.success('Insurance Added Successfully!'); // Display message in an alert box
    };

    const handleInsuranceEdit = (appointment) => {
        setModalMode('edit');
        // Populate the form fields with the details of the medication being edited
        setEditedAppointmentId(appointment._id);
        setAddProvider(appointment.provider);
        setAddPolicyNumber(appointment.policyNumber);
        setAddValidityDate(parseISO(appointment.validityDate));

        // Show the modal dialog
        setShow(true);
    };

    const handleInsuranceEditSubmit = async () => {
        if (!addValidityDate || !addProvider || !addPolicyNumber) {
            setShowAddInsuranceError(true);
            return;
        }

        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: editedAppointmentId,
                provider: addProvider,
                validityDate: addValidityDate,
                policyNumber: addPolicyNumber,
            }),
        };

        const response = await fetch(
            `https://medharbor-backend.onrender.com/insurances/${editedAppointmentId}`,
            requestOptions
        );
        const data = await response.json();

        // Clear the Modal form and close the Modal after successful update
        handleClose();
        toast.success('Insurance Updated Successfully!'); // Display message in an alert box

        fetchRecordData('insurances');
    };

    const deleteInsurance = async (appointmentId) => {
        // Send a request to delete the record from the database
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: appointmentId,
            }),
        };

        const response = await fetch(
            `https://medharbor-backend.onrender.com/insurances/${appointmentId}`,
            requestOptions
        );

        // Update the UI by removing the deleted record from medicationsData
        setInsuranceData((prevData) =>
            prevData.filter((item) => item._id !== appointmentId)
        );

        // Optionally, display a confirmation message
        toast.success('Insurance Deleted Successfully!');

        fetchRecordData('insurances');
    };

    const formatDate = (dateString) => {
        const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

        if (isoFormat.test(dateString)) {
            const date = new Date(dateString);
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();

            return `${month}-${day}-${year}`;
        }

        return dateString;
    };

    const handleBillingHistorySave = () => {
        if (modalMode === 'edit') {
            handleBillingHistoryEditSubmit();
        } else {
            addBillingHistory();
        }
    };

    const addBillingHistory = async () => {
        setModalMode('add');

        if (!addDate || !addDescription || !addAmount) {
            setShowAddBillingHistoryError(true);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                creator: auth.user.id,
                date: addDate,
                description: addDescription,
                amount: addAmount,
            }),
        };

        const response = await fetch(
            'https://medharbor-backend.onrender.com/billingHistory',
            requestOptions
        );

        const data = await response.json();

        fetchRecordData('billingHistory');

        setSortedData([
            {
                date: addDate,
                description: addDescription,
                amount: addAmount,
            },
            ...sortedData,
        ]);

        handleClose();
    };

    const handleBillingHistoryEdit = (appointment) => {
        setModalMode('edit');
        // Populate the form fields with the details of the medication being edited
        setEditedAppointmentId(appointment._id);
        setAddDescription(appointment.description);
        setAddAmount(appointment.amount);
        setAddDate(parseISO(appointment.date));
        // Show the modal dialog
        setShow(true);
    };

    const handleBillingHistoryEditSubmit = async () => {
        if (!addDate || !addDescription || !addAmount) {
            setShowAddBillingHistoryError(true);
            return;
        }
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: editedAppointmentId,
                description: addDescription,
                date: addDate,
                amount: addAmount,
            }),
        };
        const response = await fetch(
            `https://medharbor-backend.onrender.com/billingHistory/${editedAppointmentId}`,
            requestOptions
        );
        const data = await response.json();

        // Clear the Modal form and close the Modal after successful update
        handleClose();
        toast.success('Billing History Updated Successfully!'); // Display message in an alert box
        fetchRecordData('billingHistory');
        // fetchPreviousAppointments();
    };

    const deleteBillingHistory = async (appointmentId) => {
        // Send a request to delete the record from the database
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: appointmentId,
            }),
        };
        const response = await fetch(
            `https://medharbor-backend.onrender.com/billingHistory/${appointmentId}`,
            requestOptions
        );
        // Update the UI by removing the deleted record from medicationsData
        setPreviousAppointmentData((prevData) =>
            prevData.filter((item) => item._id !== appointmentId)
        );
        // Optionally, display a confirmation message
        toast.success('Billing History Deleted Successfully!');
        fetchRecordData('billingHistory');
        // fetchPreviousAppointments();
    };

    const [showMedicalRecordForm, setShowMedicalRecordForm] = useState(false);
    const [selectedFile, setSelectedFile] = useState(false);

    const addMedicalRecord = async () => {
        setModalMode('add');

        if (!selectedFile) {
            alert('No file selected!');
            return;
        }

        try {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = async () => {
                const base64Data = reader.result.split(',')[1]; // Extract base64 data from data URL

                const payload = {
                    creator: auth.user.id,
                    imageName: selectedFile.name,
                    imageData: base64Data,
                    contentType: selectedFile.type,
                };

                const response = await fetch(
                    'https://medharbor-backend.onrender.com/medicalRecords',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    }
                );

                const data = await response.json();

                if (response.ok) {
                    fetchRecordData('medicalRecords');
                    handleClose();
                    toast.success('Medical Image Added Successfully!');
                } else {
                    console.error('Error adding medical record:', data);
                    toast.error(`Error: ${data.message}`);
                }
            };
        } catch (error) {
            console.error('Error adding medical record:', error);
            toast.error('An error occurred while adding the medical record.');
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const stripFileExtension = (fileName) => {
        return fileName.replace(/\.[^/.]+$/, '');
    };

    const base64ToBlob = (base64, contentType) => {

        try {
            // Ensure the base64 data is a string or a buffer object
            let base64String = '';

            if (typeof base64 === 'string') {
                base64String = base64;
            } else if (base64.type === 'Buffer' && Array.isArray(base64.data)) {
                base64String = bufferToBase64(base64.data);
            } else {
                throw new Error(
                    'Invalid base64 data: base64 is not a string or buffer'
                );
            }

            // Remove any characters not part of the base64 encoding
            const sanitizedBase64 = base64String.replace(/[^A-Za-z0-9+/=]/g, '');

            // Decode the sanitized base64 string into byte characters
            const byteCharacters = atob(sanitizedBase64);
            const byteNumbers = new Array(byteCharacters.length);

            // Convert byte characters to byte numbers
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            // Create a Uint8Array from the byte numbers
            const byteArray = new Uint8Array(byteNumbers);

            // Create and return a Blob object from the Uint8Array with the specified content type
            return new Blob([byteArray], { type: contentType });
        } catch (error) {
            console.error('Error in base64ToBlob:', error);
            throw error;
        }
    };

    const bufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;

        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return binary;
    };

    const downloadFile = (appointment) => {
        if (
            !appointment ||
            !appointment.contentType ||
            !appointment.imageData
        ) {
            alert('File data is incomplete. Cannot download.');
            return;
        }

        try {
            // Convert binary data to Base64 string
            const byteArray = new Uint8Array(appointment.imageData.data);
            const base64String = bufferToBase64(byteArray);

            const blob = base64ToBlob(base64String, appointment.contentType);

            const url = URL.createObjectURL(blob);

            // const fileName = stripFileExtension(appointment.imageName).split(' ').join('');
            const fileName = stripFileExtension(appointment.imageName);
            
            const link = document.createElement('a');
            link.href = url;
            link.download = `${fileName}.${getExtension(appointment.contentType)}`;

            // Append the link to the document body
            document.body.appendChild(link);

            // Trigger the click event to initiate download
            link.click();

            // Clean up: Remove the link and revoke the object URL
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            toast.error('An error occurred while downloading the file.');
            console.error('Error downloading file:', error);
        }
    };

    const getExtension = (contentType) => {
        const extensions = {
            'image/jpeg': 'jpeg',
            'image/png': 'png',
            'application/pdf': 'pdf',
            // Add more content types and their extensions as needed
        };
        return extensions[contentType] || '';
    };

    const downloadFile1 = (fileName, fileData, contentType) => {
        try {
            const blob = base64ToBlob(fileData, contentType);
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.innerHTML = fileName; // Set the link text to the file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('An error occurred while downloading the file.');
        }
    };

    const deleteMedicalRecord = async (Id) => {

        // Update the UI by removing the deleted record from medicationsData
        setMedicalRecords((prevData) => 
            prevData.filter((item) => item._id !== Id)
        );
        setFilteredMedicalRecords(medicalRecords);
        setDeleteModal(false);

        // Send a request to delete the record from the database
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _id: Id,
            }),
        };

        const response = await fetch(
            `https://medharbor-backend.onrender.com/medicalRecords/${Id}`,
            requestOptions
        );

        fetchRecordData('medicalRecords');

        // Optionally, display a confirmation message
        toast.success('Medical Record Deleted Successfully!');

    };

    const handleMedicalRecordSave = () => {
        if (modalMode === 'edit') {
            handleMedicalRecordEditSubmit();
        } else {
            addMedicalRecord();
        }
    };

    const handleMedicalRecordEdit = (appointment) => {
        setModalMode('edit');
        // Populate the form fields with the details of the medication being edited
        setEditedAppointmentId(appointment._id);
        // Show the modal dialog
        setShowMedicalRecordForm(true);
    };

    const handleMedicalRecordEditSubmit = async () => {
        if (!selectedFile) {
            alert('No file selected!');
            return;
        }

        const fileToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        try {
            const base64File = await fileToBase64(selectedFile);
            const payload = {
                imageName: selectedFile.name,
                imageData: base64File.split(',')[1],
                contentType: selectedFile.type,
                _id: editedAppointmentId,
            };

            const response = await fetch(
                `https://medharbor-backend.onrender.com/medicalRecords/${editedAppointmentId}`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                }
            );

            const data = await response.json();

            if (response.ok) {
                fetchRecordData('medicalRecords');
                toast.success('Medical Image Updated Successfully!');
                handleClose();
            } else {
                console.error('Error updating medical record:', data);
                toast.error(`Error: ${data.message}`);
            }
        } catch (error) {
            console.error('Error updating medical record:', error);
            toast.error('An error occurred while updating the medical record.');
        }
    };

    const handlePreviousAppointmentDateChange = (date) => {
        const updatedDetails = { ...previousAppointmentDetails, date };
        setPreviousAppointmentDetails(updatedDetails);
        validateField('date', date);
    };

    const handleSearchChange = (event) => {
        const { value } = event.currentTarget;
        setSearch(value);

        if (selectedTab === 'previous') {
            const sortedAndFilteredData = sortDataNew(previousAppointments, {
                reversed: false,
                search: value,
            });
            setFilteredPreviousAppointments(sortedAndFilteredData);
        } else if (selectedTab === 'upcoming') {
            const sortedAndFilteredData = sortDataNew(upcomingAppointments, {
                reversed: false,
                search: value,
            });
            setFilteredUpcomingAppointments(sortedAndFilteredData);
        } else if (selectedTab === 'billing') {
            const sortedAndFilteredData = sortDataNew(billingHistory, {
                reversed: false,
                search: value,
            });
            setFilteredBillingHistory(sortedAndFilteredData);
        } else if (selectedTab === 'ins') {
            const sortedAndFilteredData = sortDataNew(insurance, {
                reversed: false,
                search: value,
            });
            setFilteredInsurance(sortedAndFilteredData);
        } else if (selectedTab === 'Medical') {
            const sortedAndFilteredData = sortDataNew(medicalRecords, {
                reversed: false,
                search: value,
            });
            setFilteredMedicalRecords(sortedAndFilteredData);
        } else if (selectedTab === 'all') {
            const sortedAndFilteredData = sortDataNew(allRecords, {
                reversed: false,
                search: value,
            });
            setFilteredAllRecords(sortedAndFilteredData);
        }
    };

    function sortDataNew(data, payload) {
        const { reversed, search } = payload;

        // Filter data based on search query
        const filteredData = filterData(data, search);

        // Sort filteredData (if needed)
        // Here, you can add sorting logic if required

        return filteredData;
    }

    function filterData(data, search) {
        const query = search.toLowerCase().trim();
        return data.filter((item) =>
            Object.keys(item).some((key) =>
                String(item[key]).toLowerCase().includes(query)
            )
        );
    }

    return (
        <div className="p-3">
            <h1> My Records</h1>
            <Tabs
                id="controlled-tab-example"
                activeKey={selectedTab}
                defaultActiveKey="previous"
                onSelect={(k) => handleTabClick(k)}
                className="mb-3"
            >
                <Tab eventKey="previous" title="Previous Appointments">
                    {selectedTab === 'previous' && (
                        <>
                            <div className="details-container">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                    }}
                                >
                                    <TextInput
                                        placeholder="Search..."
                                        mb="md"
                                        leftSection={
                                            <IconSearch
                                                style={{
                                                    width: rem(16),
                                                    height: rem(16),
                                                }}
                                                stroke={1.5}
                                            />
                                        }
                                        value={search}
                                        onChange={handleSearchChange}
                                        mt={30}
                                        mr={20}
                                        style={{ flexGrow: 1 }}
                                    />
                                </div>

                                <table className="appointment-table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Location</th>
                                            <th>Doctor Name</th>
                                            <th>Comments</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredPreviousAppointments &&
                                        filteredPreviousAppointments.length > 0
                                            ? filteredPreviousAppointments.map(
                                                  (appointment) => (
                                                      <tr
                                                          key={`${appointment.doctorName}-${appointment.location}-${appointment.comments}`}
                                                      >
                                                          <td>
                                                              {appointment.date
                                                                  ? format(
                                                                        new Date(
                                                                            appointment.date
                                                                        ),
                                                                        'MM/dd/yyyy'
                                                                    )
                                                                  : null}
                                                          </td>
                                                          <td>
                                                              {
                                                                  appointment.location
                                                              }
                                                          </td>
                                                          <td>
                                                              {
                                                                  appointment.doctorName
                                                              }
                                                          </td>
                                                          <td>
                                                              {
                                                                  appointment.comments
                                                              }
                                                          </td>
                                                          <td>
                                                            <div className="d-flex flex-column">

                                                              <Button
                                                                  className="edit_btn"
                                                                  variant="outline-warning"
                                                                  onClick={() =>
                                                                      openPreviousAppointmentModal(
                                                                          'Edit',
                                                                          appointment
                                                                      )
                                                                  }
                                                              >
                                                                  Edit
                                                              </Button>
                                                              <Button
                                                                  style={{
                                                                      marginTop:
                                                                          '10px',
                                                                  }}
                                                                  className="delete_btn"
                                                                  variant="outline-danger"
                                                                  onClick={() =>
                                                                      deletePreviousAppointment(
                                                                          appointment._id
                                                                      )
                                                                  }
                                                              >
                                                                  Delete
                                                              </Button>
                                                            </div>
                                                          </td>
                                                      </tr>
                                                  )
                                              )
                                            : 'No Previous Appointments Found'}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                {
                                    <>
                                        <Button
                                            className="btn btn-md"
                                            style={styles}
                                            onClick={() =>
                                                openPreviousAppointmentModal(
                                                    'Add',
                                                    null
                                                )
                                            }
                                        >
                                            Add Previous Appointment
                                        </Button>
                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    Add Previous Appointment
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>
                                                            Date
                                                        </Form.Label>{' '}
                                                        <br />
                                                        <DatePicker
                                                            selected={
                                                                previousAppointmentDetails.date
                                                                    ? new Date(
                                                                          previousAppointmentDetails.date
                                                                      )
                                                                    : new Date()
                                                            }
                                                            onChange={
                                                                handlePreviousAppointmentDateChange
                                                            }
                                                            className="form-control"
                                                            placeholderText="Select Date"
                                                        />
                                                        {errors.date && (
                                                            <Form.Control.Feedback
                                                                type="invalid"
                                                                style={{
                                                                    display:
                                                                        'block',
                                                                }}
                                                            >
                                                                {errors.date}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label>
                                                            Location
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="location"
                                                            value={
                                                                previousAppointmentDetails.location
                                                            }
                                                            isInvalid={
                                                                !!errors.location
                                                            }
                                                            placeholder="Enter Location"
                                                            onChange={
                                                                handlePreviousAppointmentInputChange
                                                            }
                                                            onBlur={(e) =>
                                                                handleBlur(
                                                                    e,
                                                                    'location'
                                                                )
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.location}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>
                                                            Doctor Name
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="doctorName"
                                                            value={
                                                                previousAppointmentDetails.doctorName
                                                            }
                                                            placeholder="Enter Doctor Name"
                                                            onChange={
                                                                handlePreviousAppointmentInputChange
                                                            }
                                                            onBlur={(e) =>
                                                                handleBlur(
                                                                    e,
                                                                    'doctorName'
                                                                )
                                                            }
                                                            isInvalid={
                                                                !!errors.doctorName
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.doctorName}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>
                                                            Comments
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="comments"
                                                            value={
                                                                previousAppointmentDetails.comments
                                                            }
                                                            onChange={
                                                                handlePreviousAppointmentInputChange
                                                            }
                                                            onBlur={(e) =>
                                                                handleBlur(
                                                                    e,
                                                                    'comments'
                                                                )
                                                            }
                                                            placeholder="Add Comments"
                                                            isInvalid={
                                                                !!errors.comments
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.comments}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button
                                                    variant="secondary"
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </Button>
                                                <Button
                                                    disabled={isDisabled()}
                                                    variant="primary"
                                                    onClick={handleSave}
                                                >
                                                    Save
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </>
                                }
                            </div>
                        </>
                    )}{' '}
                </Tab>
                <Tab eventKey="upcoming" title="Upcoming Appointments">
                    {selectedTab === 'upcoming' && (
                        <>
                            <div className="details-container">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                    }}
                                >
                                    <TextInput
                                        placeholder="Search..."
                                        mb="md"
                                        leftSection={
                                            <IconSearch
                                                style={{
                                                    width: rem(16),
                                                    height: rem(16),
                                                }}
                                                stroke={1.5}
                                            />
                                        }
                                        value={search}
                                        onChange={handleSearchChange}
                                        mt={30}
                                        mr={20}
                                        style={{ flexGrow: 1 }}
                                    />
                                </div>
                                <table className="appointment-table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Location</th>
                                            <th>Doctor Name</th>
                                            <th>Phone Number</th>
                                            <th>Comments</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredUpcomingAppointments.length > 0
                                            ? filteredUpcomingAppointments.map(
                                                  (appointment) => (
                                                      <tr
                                                          key={`${appointment.doctorName}-${appointment.location}-${appointment.comments}`}
                                                      >
                                                          <td>
                                                              {formatDate(
                                                                  appointment.date
                                                              )}
                                                          </td>
                                                          <td>
                                                              {
                                                                  appointment.location
                                                              }
                                                          </td>
                                                          <td>
                                                              {
                                                                  appointment.doctorName
                                                              }
                                                          </td>
                                                          <td>
                                                              {
                                                                  appointment.phoneNumber
                                                              }
                                                          </td>
                                                          <td>
                                                              {
                                                                  appointment.comments
                                                              }
                                                          </td>
                                                          <td>
                                                              {' '}
                                                              <div className="d-flex flex-column">

                                                              <Button
                                                                  className="edit_btn"
                                                                  variant="outline-warning"
                                                                  onClick={() =>
                                                                      openUpcomingAppointmentModal(
                                                                          'Edit',
                                                                          appointment
                                                                      )
                                                                  }
                                                              >
                                                                  Edit
                                                              </Button>
                                                              <Button
                                                                  style={{
                                                                      marginTop:
                                                                          '10px',
                                                                  }}
                                                                  className="delete_btn ml-1"
                                                                  variant="outline-danger"
                                                                  onClick={() =>
                                                                      openDeleteModal(
                                                                          appointment,
                                                                          '/upcomingAppointments'
                                                                      )
                                                                  }
                                                              >
                                                                  Delete
                                                              </Button>
                                                              </div>
                                                          </td>
                                                      </tr>
                                                  )
                                              )
                                            : 'No Upcoming Appointments Found'}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <Button
                                    className="btn btn-md"
                                    style={styles}
                                    onClick={() =>
                                        openUpcomingAppointmentModal(
                                            'Add',
                                            null
                                        )
                                    }
                                >
                                    Add Upcoming Appointment
                                </Button>

                                <Modal
                                    show={upcomingAppointmentTab}
                                    onHide={closeUpcomingAppointmentModal}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {action === 'Add' ? 'Add' : 'Edit'}{' '}
                                            Upcoming Appointment
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group
                                                style={{ marginTop: 10 }}
                                            >
                                                <Form.Label>Date</Form.Label>
                                                <DatePicker
                                                    selected={
                                                        new Date(
                                                            upcomingAppointmentDetails.date
                                                        )
                                                    }
                                                    onChange={handleDateChange}
                                                    className="form-control"
                                                    placeholderText="Select Date"
                                                />
                                                {errors.date && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        style={{
                                                            display: 'block',
                                                        }}
                                                    >
                                                        {errors.date}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Form.Group
                                                style={{ marginTop: 30 }}
                                            >
                                                <Form.Label>
                                                    Location
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="location"
                                                    value={
                                                        upcomingAppointmentDetails.location
                                                    }
                                                    onChange={
                                                        handleUpcomingAppointmentInputChange
                                                    }
                                                    onBlur={(e) =>
                                                        handleBlur(
                                                            e,
                                                            'location'
                                                        )
                                                    }
                                                    placeholder="Add the Location Name"
                                                    isInvalid={
                                                        !!errors.location
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.location}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group
                                                style={{ marginTop: 30 }}
                                            >
                                                <Form.Label>
                                                    Doctor Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="doctorName"
                                                    value={
                                                        upcomingAppointmentDetails.doctorName
                                                    }
                                                    onChange={
                                                        handleUpcomingAppointmentInputChange
                                                    }
                                                    onBlur={(e) =>
                                                        handleBlur(
                                                            e,
                                                            'doctorName'
                                                        )
                                                    }
                                                    placeholder="Mention the name of Doctor"
                                                    isInvalid={
                                                        !!errors.doctorName
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.doctorName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group
                                                style={{ marginTop: 30 }}
                                            >
                                                <Form.Label>
                                                    Phone Number
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="phoneNumber"
                                                    value={
                                                        upcomingAppointmentDetails.phoneNumber
                                                    }
                                                    onChange={
                                                        handleUpcomingAppointmentInputChange
                                                    }
                                                    onBlur={(e) =>
                                                        handleBlur(
                                                            e,
                                                            'phoneNumber'
                                                        )
                                                    }
                                                    placeholder="Add Phone Number"
                                                    isInvalid={
                                                        !!errors.phoneNumber
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.phoneNumber}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group
                                                style={{ marginTop: 30 }}
                                            >
                                                <Form.Label>
                                                    Comments
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="comments"
                                                    value={
                                                        upcomingAppointmentDetails.comments
                                                    }
                                                    onChange={
                                                        handleUpcomingAppointmentInputChange
                                                    }
                                                    onBlur={(e) =>
                                                        handleBlur(
                                                            e,
                                                            'comments'
                                                        )
                                                    }
                                                    placeholder="Add Comments"
                                                    isInvalid={
                                                        !!errors.comments
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.comments}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant="secondary"
                                            onClick={
                                                closeUpcomingAppointmentModal
                                            }
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            disabled={isDisabled()}
                                            onClick={
                                                addUpcomingAppointmentDetails
                                            }
                                        >
                                            Save
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </>
                    )}
                </Tab>
                <Tab eventKey="billing" title="Billing Records">
                    {selectedTab === 'billing' && (
                        <>
                            <div className="details-container">
                                {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                    ></Spinner>
                                ) : (
                                    <div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'baseline',
                                            }}
                                        >
                                            <TextInput
                                                placeholder="Search..."
                                                mb="md"
                                                leftSection={
                                                    <IconSearch
                                                        style={{
                                                            width: rem(16),
                                                            height: rem(16),
                                                        }}
                                                        stroke={1.5}
                                                    />
                                                }
                                                value={search}
                                                onChange={handleSearchChange}
                                                mt={30}
                                                mr={20}
                                                style={{ flexGrow: 1 }}
                                            />
                                        </div>
                                        <div>
                                            <table className="appointment-table">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Description</th>
                                                        <th>Amount</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredBillingHistory && filteredBillingHistory.length > 0
                                                        ? filteredBillingHistory.map(
                                                            (appointment) => (
                                                                <tr
                                                                    key={`${appointment.description}-${appointment.amount}`}
                                                                >
                                                                    <td>
                                                                        {format(
                                                                            new Date(
                                                                                appointment.date
                                                                            ),
                                                                            'MM/dd/yyyy'
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            appointment.description
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            appointment.amount
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {' '}
                                                                        <div className="d-flex flex-column">

                                                                        
                                                                        <Button
                                                                            onClick={() =>
                                                                                handleBillingHistoryEdit(
                                                                                    appointment
                                                                                )
                                                                            }
                                                                            className="edit_btn"
                                                                            variant="outline-warning"
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                        <Button
                                                                            className="delete_btn"
                                                                            variant="outline-danger"
                                                                            style={{marginTop: '10px'}}
                                                                            onClick={() =>
                                                                                deleteBillingHistory(
                                                                                    appointment._id
                                                                                )
                                                                            }
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )
                                                        : 'No Data Found For Billing History'}
                                                </tbody>
                                            </table>{' '}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                <>
                                    <Button
                                        className="btn btn-md"
                                        style={styles}
                                        onClick={handleShow}
                                    >
                                        Add Billing History
                                    </Button>
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Add Billing History
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {showAddBillingHistoryError && (
                                                <Alert
                                                    key="danger"
                                                    variant="danger"
                                                >
                                                    Please fill in all the
                                                    details
                                                </Alert>
                                            )}
                                            <Form>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Date
                                                    </Form.Label>{' '}
                                                    <br />
                                                    <DatePicker
                                                        selected={addDate}
                                                        onChange={(date) =>
                                                            setAddDate(date)
                                                        }
                                                        className="form-control"
                                                        placeholderText="Select Date"
                                                    />
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label>
                                                        Description
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={addDescription}
                                                        placeholder="Enter Description"
                                                        onChange={(e) =>
                                                            setAddDescription(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label>
                                                        Amount
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={addAmount}
                                                        placeholder="Enter Amount"
                                                        onChange={(e) =>
                                                            setAddAmount(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant="secondary"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </Button>
                                            <Button
                                                variant="primary"
                                                // onClick={modalMode === 'edit' ? handleEditSubmit : addMedication}
                                                onClick={
                                                    handleBillingHistorySave
                                                }
                                            >
                                                Save
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </>
                            </div>
                        </>
                    )}
                </Tab>
                <Tab eventKey="ins" title="Insurance Records">
                    {selectedTab === 'ins' && (
                        <>
                            <div className="details-container">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                    }}
                                >
                                    <TextInput
                                        placeholder="Search..."
                                        mb="md"
                                        leftSection={
                                            <IconSearch
                                                style={{
                                                    width: rem(16),
                                                    height: rem(16),
                                                }}
                                                stroke={1.5}
                                            />
                                        }
                                        value={search}
                                        onChange={handleSearchChange}
                                        mt={30}
                                        mr={20}
                                        style={{ flexGrow: 1 }}
                                    />
                                </div>
                                <table className="appointment-table">
                                    <thead>
                                        <tr>
                                            <th>Validity Date</th>
                                            <th>Provider</th>
                                            <th>Policy Number</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredInsurance && filteredInsurance.length > 0
                                            ? filteredInsurance.map((appointment) => (
                                                  <tr
                                                      key={`${appointment.provider}-${appointment.policyNumber}`}
                                                  >
                                                      <td>
                                                          {format(
                                                              new Date(
                                                                  appointment.validityDate
                                                              ),
                                                              'MM/dd/yyyy'
                                                          )}
                                                      </td>
                                                      <td>
                                                          {appointment.provider}
                                                      </td>
                                                      <td>
                                                          {
                                                              appointment.policyNumber
                                                          }
                                                      </td>
                                                      <td>
                                                          {' '}
                                                          <div className="d-flex flex-column">

                                                          <Button
                                                              onClick={() =>
                                                                  handleInsuranceEdit(
                                                                      appointment
                                                                  )
                                                              }
                                                              className="edit_btn"
                                                              variant="outline-warning"
                                                          >
                                                              Edit
                                                          </Button>
                                                          <Button
                                                              className="delete_btn"
                                                              variant="outline-danger"
                                                              style={{marginTop: '10px'}}
                                                              onClick={() =>
                                                                  deleteInsurance(
                                                                      appointment._id
                                                                  )
                                                              }
                                                          >
                                                              Delete
                                                          </Button>
                                                          </div>
                                                      </td>
                                                  </tr>
                                              ))
                                            : 'No Insurance Details Found'}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                {
                                    <>
                                        <Button
                                            className="btn btn-md"
                                            style={styles}
                                            onClick={handleShow}
                                        >
                                            Add Insurance
                                        </Button>
                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    Add Insurance
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {showAddInsuranceError && (
                                                    <Alert
                                                        key="danger"
                                                        variant="danger"
                                                    >
                                                        Please fill in all the
                                                        details
                                                    </Alert>
                                                )}
                                                <Form>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>
                                                            Date
                                                        </Form.Label>{' '}
                                                        <br />
                                                        <DatePicker
                                                            selected={
                                                                addValidityDate
                                                            }
                                                            onChange={(date) =>
                                                                setAddValidityDate(
                                                                    date
                                                                )
                                                            }
                                                            className="form-control"
                                                            placeholderText="Select Date"
                                                        />
                                                    </Form.Group>

                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label>
                                                            Provider
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={addProvider}
                                                            placeholder="Add Provider Name"
                                                            onChange={(e) =>
                                                                setAddProvider(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label>
                                                            Policy Number
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={
                                                                addPolicyNumber
                                                            }
                                                            placeholder="Mention Policy Number"
                                                            onChange={(e) =>
                                                                setAddPolicyNumber(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button
                                                    variant="secondary"
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </Button>
                                                <Button
                                                    variant="primary"
                                                    // onClick={modalMode === 'edit' ? handleEditSubmit : addMedication}
                                                    onClick={
                                                        handleInsuranceSave
                                                    }
                                                >
                                                    Save
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </>
                                }
                            </div>
                        </>
                    )}
                </Tab>
                <Tab eventKey="Medical" title="Medical Records">
                    {selectedTab === 'Medical' && (
                        <>
                            <div className="details-container">
                                {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                    ></Spinner>
                                ) : (
                                    <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                        }}
                                    >
                                        <TextInput
                                            placeholder="Search..."
                                            mb="md"
                                            leftSection={
                                                <IconSearch
                                                    style={{
                                                        width: rem(16),
                                                        height: rem(16),
                                                    }}
                                                    stroke={1.5}
                                                />
                                            }
                                            value={search}
                                            onChange={handleSearchChange}
                                            mt={30}
                                            mr={20}
                                            style={{ flexGrow: 1 }}
                                        />
                                    </div>
                                    <table className="appointment-table">
                                        <thead>
                                            <th>Image</th>
                                            <th>Actions</th>
                                        </thead>
                                        <tbody>
                                            {filteredMedicalRecords &&
                                                filteredMedicalRecords.length > 0
                                                ? filteredMedicalRecords.map(
                                                      (appointment) => (
                                                          <tr
                                                              key={`${appointment._id}`}
                                                          >
                                                              <td>
                                                                  {/* {stripFileExtension(appointment.imageName)} */}
                                                                  <a
                                                                      href="#"
                                                                      onClick={() =>
                                                                          downloadFile1(
                                                                              appointment.imageName,
                                                                              appointment.imageData,
                                                                              appointment.contentType
                                                                          )
                                                                      }
                                                                  >
                                                                      {
                                                                          appointment.imageName
                                                                      }
                                                                  </a>
                                                              </td>
                                                              <td>
                                                                  {' '}
                                                                  <div className="d-flex flex-column">
                                                                  <Button
                                                                      onClick={() =>
                                                                          handleMedicalRecordEdit(
                                                                              appointment
                                                                          )
                                                                      }
                                                                      className="edit_btn"
                                                                      variant="outline-warning"
                                                                      
                                                                  >
                                                                      Edit
                                                                  </Button>
                                                                  <Button
                                                                      className="delete_btn"
                                                                      variant="outline-danger"
                                                                      onClick={() =>
                                                                          deleteMedicalRecord(
                                                                              appointment._id
                                                                          )
                                                                      }
                                                                      style={{
                                                                          marginTop:
                                                                              '10px',
                                                                      }}
                                                                  >
                                                                      Delete
                                                                  </Button>
                                                                  <Button
                                                                      variant="outline-primary"
                                                                      onClick={() =>
                                                                          downloadFile(
                                                                              appointment
                                                                          )
                                                                      }
                                                                      style={{marginTop: '10px'}}
                                                                  >
                                                                      Download
                                                                  </Button>
                                                                  </div>
                                                              </td>
                                                          </tr>
                                                      )
                                                  )
                                                : 'No Medical Records Found'}
                                        </tbody>
                                    </table>
                                    </div>
                                )}
                            </div>
                            <div>
                                <Button
                                    className="btn btn-md"
                                    type="button"
                                    style={styles}
                                    // onClick={() =>
                                    //     openMedicalRecordsModal('Add', null)
                                    // }
                                    onClick={() =>
                                        setShowMedicalRecordForm(true)
                                    }
                                >
                                    Add Medical Records
                                </Button>

                                <Modal
                                    show={showMedicalRecordForm}
                                    onHide={handleClose}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {modalMode === 'add'
                                                ? 'Upload Medical Records Image'
                                                : 'Edit Medical Records Image'}
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="file-input-container">
                                            <input
                                                id="file-upload"
                                                type="file"
                                                accept=".jpeg,.png,.pdf"
                                                onChange={handleFileChange}
                                                required
                                                className="file-input"
                                            />
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={handleMedicalRecordSave}
                                        >
                                            {modalMode === 'add'
                                                ? 'Upload'
                                                : 'Save Changes'}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </>
                    )}
                </Tab>

                <Tab eventKey="all" title="All Records">
                    {selectedTab === 'all' && (
                        <>
                            <div className="details-container">
                                {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                    ></Spinner>
                                ) : (
                                    <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                        }}
                                    >
                                        <TextInput
                                            placeholder="Search..."
                                            mb="md"
                                            leftSection={
                                                <IconSearch
                                                    style={{
                                                        width: rem(16),
                                                        height: rem(16),
                                                    }}
                                                    stroke={1.5}
                                                />
                                            }
                                            value={search}
                                            onChange={handleSearchChange}
                                            mt={30}
                                            mr={20}
                                            style={{ flexGrow: 1 }}
                                        />
                                    </div>
                                    <table className="appointment-table">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Test Type</th>
                                                <th>Result</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredAllRecords && filteredAllRecords.length > 0
                                                ? filteredAllRecords.map(
                                                      (allRecords) => (
                                                          <tr
                                                              key={`${allRecords.type}-${allRecords.result}`}
                                                          >
                                                              <td>
                                                                  {format(
                                                                      new Date(
                                                                          allRecords.date
                                                                      ),
                                                                      'MM/dd/yyyy'
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      allRecords.type
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {
                                                                      allRecords.result
                                                                  }
                                                              </td>

                                                              <td>
                                                                  {' '}
                                                                  <div className="d-flex flex-column">
                                                                  <Button
                                                                      className="edit_btn"
                                                                      variant="outline-warning"
                                                                      onClick={() =>
                                                                          handleEditAll(
                                                                              allRecords
                                                                          )
                                                                      }
                                                                  >
                                                                      Edit
                                                                  </Button>
                                                                  <Button
                                                                      className="delete_btn"
                                                                      variant="outline-danger"
                                                                      onClick={() =>
                                                                          deleteAllRecords(
                                                                              allRecords._id
                                                                          )
                                                                      }
                                                                       style={{marginTop: '10px'}}
                                                                  >
                                                                      Delete
                                                                  </Button>
                                                                  </div>
                                                              </td>
                                                          </tr>
                                                      )
                                                  )
                                                : 'No Records Found'}
                                        </tbody>
                                    </table>
                                    </div>
                                )}
                            </div>
                            <div>
                                <Button
                                    className="btn btn-md"
                                    style={styles}
                                    onClick={handleShow}
                                >
                                    Add All Records
                                </Button>

                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Add All Records
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {showAddAllRecordsError && (
                                            <Alert
                                                key="danger"
                                                variant="danger"
                                            >
                                                Please fill in all the details
                                            </Alert>
                                        )}
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Date</Form.Label>{' '}
                                                <br />
                                                <DatePicker
                                                    selected={addRecordDate}
                                                    onChange={(date) =>
                                                        setAddRecordDate(date)
                                                    }
                                                    className="form-control"
                                                    placeholderText="Select Date"
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label>Type</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={addType}
                                                    placeholder="Enter type"
                                                    onChange={(e) =>
                                                        setAddType(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label>Result</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={addResult}
                                                    placeholder="Enter Result"
                                                    onChange={(e) =>
                                                        setAddResult(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={handleSaveAll}
                                        >
                                            Save
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>

                            <div className="details-container">
                                {loading ? (
                                    <Spinner animation="border" role="status" />
                                ) : (
                                    <div>
                                        <h3>Previous Appointments</h3>
                                        <table className="common-table all-records-table">
                                            <thead>
                                                <tr>
                                                    <th className="col-date">
                                                        Date
                                                    </th>
                                                    <th className="col-location">
                                                        Location
                                                    </th>
                                                    <th className="col-doctor">
                                                        Doctor Name
                                                    </th>
                                                    <th className="col-comments">
                                                        Comments
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {previousAppointments &&
                                                previousAppointments.length >
                                                    0 ? (
                                                    previousAppointments.map(
                                                        (appointment) => (
                                                            <tr
                                                                key={`${appointment.doctorName}-${appointment.location}-${appointment.comments}`}
                                                            >
                                                                <td>
                                                                    {format(
                                                                        new Date(
                                                                            appointment.date
                                                                        ),
                                                                        'MM/dd/yyyy'
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        appointment.location
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        appointment.doctorName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        appointment.comments
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4">
                                                            No Previous
                                                            Appointments Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        <h3 className="section-heading">
                                            Upcoming Appointments
                                        </h3>
                                        <table className="common-table all-records-table">
                                            <thead>
                                                <tr>
                                                    <th className="col-date">
                                                        Date
                                                    </th>
                                                    <th className="col-location">
                                                        Location
                                                    </th>
                                                    <th className="col-doctor">
                                                        Doctor Name
                                                    </th>
                                                    <th className="col-phone-number">
                                                        Phone Number
                                                    </th>
                                                    <th className="col-comments">
                                                        Comments
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {upcomingAppointments &&
                                                upcomingAppointments.length >
                                                    0 ? (
                                                    upcomingAppointments.map(
                                                        (appointment) => (
                                                            <tr
                                                                key={`${appointment.doctorName}-${appointment.location}-${appointment.phoneNumber}-${appointment.comments}`}
                                                            >
                                                                <td>
                                                                    {format(
                                                                        new Date(
                                                                            appointment.date
                                                                        ),
                                                                        'MM/dd/yyyy'
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        appointment.location
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        appointment.doctorName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        appointment.phoneNumber
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        appointment.comments
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4">
                                                            No Upcoming
                                                            Appointments Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        <h3 className="section-heading">
                                            Billing History
                                        </h3>
                                        <table className="common-table all-records-table">
                                            <thead>
                                                <tr>
                                                    <th className="col-date">
                                                        Date
                                                    </th>
                                                    <th className="col-description">
                                                        Description
                                                    </th>
                                                    <th className="col-amount">
                                                        Amount
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {billingHistory &&
                                                billingHistory.length > 0 ? (
                                                    billingHistory.map(
                                                        (record) => (
                                                            <tr
                                                                key={`${record.description}-${record.amount}`}
                                                            >
                                                                <td>
                                                                    {format(
                                                                        new Date(
                                                                            record.date
                                                                        ),
                                                                        'MM/dd/yyyy'
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        record.description
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        record.amount
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3">
                                                            No Billing History
                                                            Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        <h3 className="section-heading">
                                            Insurance Records
                                        </h3>
                                        <table className="common-table all-records-table">
                                            <thead>
                                                <tr>
                                                    <th className="col-date">
                                                        Validity Date
                                                    </th>
                                                    <th className="col-provider">
                                                        Provider
                                                    </th>
                                                    <th className="col-policy">
                                                        Policy Number
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {insurance &&
                                                insurance.length > 0 ? (
                                                    insurance.map((record) => (
                                                        <tr
                                                            key={`${record.provider}-${record.policyNumber}`}
                                                        >
                                                            <td>
                                                                {format(
                                                                    new Date(
                                                                        record.validityDate
                                                                    ),
                                                                    'MM/dd/yyyy'
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    record.provider
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    record.policyNumber
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3">
                                                            No Insurance Details
                                                            Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </Tab>
            </Tabs>

            {(!auth || !auth.user) && <LoginPopup />}
            <Modal show={deleteModal} onHide={closeDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Record </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> Are you sure you want to delete the record ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteModal}>Cancel</Button>
                    <Button variant="primary" onClick={deleteRecord}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default App;
