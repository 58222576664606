import React, { useState } from "react";
import '../styles/chipInput.css';
import { Cross1Icon } from "@radix-ui/react-icons";

function ChipInput() {
  const [text, setText] = useState("");
  const [chips, setChips] = useState([]);
  const [validationError, setValidationError] = useState("");

  function removeChip(chipToRemove) {
    const updatedChips = chips.filter((chip) => chip !== chipToRemove);
    setChips(updatedChips);
  }

  function handlePressEnter(e) {
    if (e.key === "Enter") e.preventDefault();
    if (e.key !== "Enter" || !text) return;
    if (chips.includes(text)) {
      return setValidationError("Cannot add the same input more than once");
    }
    setChips((prevState) => [...prevState, text]);
    setText("");
    setValidationError("");
  }

  return (
    <div>
      <label htmlFor="tags">Additional Symptoms</label>
      <div className="input-container">
        <ul className="chips">
          {chips.map((chip) => (
            <li key={chip} className="chip">
              <span>{chip}</span>
              <Cross1Icon onClick={() => removeChip(chip)} tabIndex="0" />
            </li>
          ))}
        </ul>
        <input
          type="text"
          id="tags"
          placeholder="Press Enter to add"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handlePressEnter}
        />
      </div>
      {validationError && <p className="error-message">{validationError}</p>}
    </div>
  );
}

export default ChipInput;
