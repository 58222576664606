import Alert from 'react-bootstrap/Alert';
import React from 'react';


const HealthQuote = () => {
  return (
    <Alert style={{ backgroundColor: '#082144', color: '#FFFFFF'}}>
      <Alert.Heading style={{color:'yellow'}}>Health Quote of the Day</Alert.Heading>
      <p>
        Health is a state of complete physical, mental and social well-being, and not merely the absence of disease or infirmity.
      </p>
      <hr />
      <p className="mb-0">
        Did you hit your water goal today?
      </p>
    </Alert>
  )
}

export default HealthQuote