
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const MealCalorie = ({ date, onSubmit, disabled }) => {
    const [mealType, setMealType] = useState('Breakfast');
    const [mealName, setMealName] = useState('');
    const [calories, setCalories] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ mealName, calories: parseInt(calories), type: mealType });
        setMealName('');
        setCalories('');
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="mealType">
                <Form.Label>Meal Type</Form.Label>
                <Form.Control as="select" value={mealType} onChange={(e) => setMealType(e.target.value)} disabled={disabled}>
                    <option>Breakfast</option>
                    <option>Lunch</option>
                    <option>Snack</option>
                    <option>Dinner</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="mealName">
                <Form.Label>Meal Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter your meal"
                    value={mealName}
                    onChange={(e) => setMealName(e.target.value)}
                    disabled={disabled}
                />
            </Form.Group>
            <Form.Group controlId="calories">
                <Form.Label>Calories of Meal</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Enter calories"
                    value={calories}
                    onChange={(e) => setCalories(e.target.value)}
                    disabled={disabled}
                />
            </Form.Group>
            <div className="button-container">
            <Button variant="primary" type="submit" disabled={disabled}>
                Add Meal
            </Button>
            </div>
        </Form>
    );
};

export default MealCalorie;
