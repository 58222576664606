import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import DatePicker from 'react-datepicker';
import '../styles/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCapsules } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../providers';
import toast from 'react-hot-toast';

export default function AddMedicationPopup({show, handleCloseMedicationpopUp}) {
    const auth = useAuth();
    const initialState = {
        medicineName: '',
        dosage: '',
        dispensed: '',
        lastFilled: null,
        lastPrescribed: null,
        frequency: '',
    };

    const [formState, setFormState] = useState(initialState);
    const [addMedicineName, setAddMedicineName] = useState('');
    const [addMedicineDosage, setAddMedicineDosage] = useState('');
    const [addMedicineDispensed, setAddMedicineDispensed] = useState('');
    const [addMedicineFrequency, setAddMedicineFrequency] = useState('');
    const [addMedicineLastFilled, setAddMedicineLastFilled] = useState(
        new Date()
    );
    const [hovered, setHovered] = useState(false);

    const [addMedicineLastPrescribed, setAddMedicineLastPrescribed] = useState(
        new Date()
    );
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    //const handleShow = () => setShow(true);
    useEffect(() => {
        console.log('use effect called ');
        if (show) {
            setAddMedicineName('');
            setAddMedicineDosage('');
            setAddMedicineDispensed('');
            setAddMedicineFrequency('');
            setErrors({});
            setTouched({});
        }
    }, [show]);
    

    const allFieldsTouched = () => {
        // Check if all fields have been touched
        return touched.name && touched.dosage && touched.dispensed && touched.frequency;
    };

    const handleBlur = (event, fieldName) => {
        const { value } = event.target;
        validateField(fieldName, value);
    };

    const isDisabled = Object.keys(errors).length > 0 || !allFieldsTouched();

    const validateField = (name, value) => {
        let fieldErrors = { ...errors };
        let isTouched = { ...touched };
        isTouched[name] = true;

        switch (name) {
            case 'name':
                if (!value) {
                    fieldErrors[name] = 'Medicine name is required';
                } else if (!/^[A-Za-z\s]+$/.test(value)) {
                    fieldErrors[name] =
                        'Medicine name must contain only alphabets';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'dosage':
                if (!value) {
                    fieldErrors[name] = 'Dosage is required';
                } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                    fieldErrors[name] =
                        'Dosage must not contain special characters';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'dispensed':
                if (!value) {
                    fieldErrors[name] = 'Total dispensed is required';
                } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                    fieldErrors[name] =
                        'Dispensed must not contain special characters';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'frequency':
                if (!value) {
                    fieldErrors[name] = 'Frequency is required';
                } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                    fieldErrors[name] =
                        'Frequency must not contain special characters';
                } else {
                    delete fieldErrors[name];
                }
                break;
            default:
                break;
        }

        setErrors(fieldErrors);
        setTouched(isTouched); // Update the touched state
    };

    const handleInputChange = (event, fieldName) => {
        const { value } = event.target;
        switch (fieldName) {
            case 'name':
                setAddMedicineName(value);
                break;
            case 'dosage':
                setAddMedicineDosage(value);
                break;
            case 'dispensed':
                setAddMedicineDispensed(value);
                break;
            case 'frequency':
                setAddMedicineFrequency(value);
                break;
            default:
                break;
        }
        validateField(fieldName, value);
    };

    const saveMedicineFilled = async () => {
        const formattedLastFilledDate = addMedicineLastFilled
            .toISOString()
            .split('T')[0];
        const formattedLastPrescribedDate = addMedicineLastPrescribed
            .toISOString()
            .split('T')[0];
        const url =
            'https://medharbor-backend.onrender.com/medication/createMedication';
        const medication = {
            userId: auth.user.id,
            medicationName: addMedicineName,
            dosage: addMedicineDosage,
            dispensed: addMedicineDispensed,
            lastFilledDate: formattedLastFilledDate,
            lastPrescribedDate: formattedLastPrescribedDate,
            frequency: addMedicineFrequency,
        };

        console.log('this data', medication);
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(medication),
        });
        try {
            if (!res.ok) {
                throw new Error('Failed to post add Medicine');
            }
            console.log('add Medicine posted successfully', res);
            handleCloseMedicationpopUp();
            const newWater = await res.json();
            console.log('new post add Medicine', newWater);
            return toast.success('Medicine added successfully');
        } catch (error) {
            console.error('Error posting post add Medicine:', error.message);
            return toast.error(error.message);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (Object.keys(errors).length === 0) {
            console.log('Form is valid');
            // Submit form logic here
        } else {
            console.log('Form is invalid');
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleCloseMedicationpopUp} >
                <Modal.Header closeButton>
                    <Modal.Title>Add medicine</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Medicine name</Form.Label>
                            <Form.Control
                                type="text"
                                value={addMedicineName}
                                placeholder="Enter medicine"
                                onChange={(e) => handleInputChange(e, 'name')}
                                onBlur={(e) => handleBlur(e, 'name')}

                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Dosage</Form.Label>
                            <Form.Control
                                type="text"
                                value={addMedicineDosage}
                                placeholder="Enter dosage"
                                onChange={(e) => handleInputChange(e, 'dosage')}
                                onBlur={(e) => handleBlur(e, 'dosage')}

                                isInvalid={!!errors.dosage}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.dosage}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Dispensed</Form.Label>
                            <Form.Control
                                type="text"
                                value={addMedicineDispensed}
                                placeholder="Enter total dispensed"
                                onChange={(e) =>
                                    handleInputChange(e, 'dispensed')
                                }
                         
                                onBlur={(e) => handleBlur(e, 'dispensed')}

                                isInvalid={!!errors.dispensed}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.dispensed}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Last filled</Form.Label>
                            <DatePicker
                                selected={addMedicineLastFilled}
                                onChange={(date) =>
                                    setAddMedicineLastFilled(date)
                                }
                                style={{ width: '100%' }}
                                id="addMedicineLastFilled"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Last prescribed</Form.Label>
                            <DatePicker
                                selected={addMedicineLastPrescribed}
                                onChange={(date) =>
                                    setAddMedicineLastPrescribed(date)
                                }
                                style={{ width: '100%' }}
                                id="addMedicineLastPrescribed"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Frequency</Form.Label>
                            <Form.Control
                                type="text"
                                value={addMedicineFrequency}
                                placeholder="Enter frequency"
                                onChange={(e) =>
                                    handleInputChange(e, 'frequency')
                                }
                                onBlur={(e) => handleBlur(e, 'frequency')}

                                isInvalid={!!errors.frequency}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.frequency}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* 
                        <Button
                            type="submit"
                            disabled={Object.keys(errors).length > 0}
                        >
                            Submit
                        </Button> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMedicationpopUp}>
                        Close
                    </Button>

                    <Button
                        variant="primary"
                        disabled={isDisabled}
                        onClick={saveMedicineFilled}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
