import React, { useState } from 'react';
import '../styles/Signup.css';
import email_icon from '../Assets/email.png';
import user_icon from '../Assets/person.png';
import password_icon from '../Assets/password.png';
import logo from '../Assets/icons8-medical-50.png';
import apple from '../Assets/apple.png';
import google from '../Assets/google_resized.png';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuth } from '../providers';

const Signup = () => {
    const [userType, setUserType] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [subscribeCheckbox, setSubscribe] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();

    const handleUserTypeChange = (e) => {
        setUserType(e.target.value);
    };

    const handleCheckboxChange = (event) => {
        setSubscribe(event.target.checked);
    };

    // Function to handle subscription
    const handleSubscription = async () => {
        try {
            const response = await fetch('https://medharbor-backend.onrender.com/newsletters/subscriber', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Subscription failed');
            }
            toast.success('Subscribed to the newsletter successfully!');
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleSignup = async () => {
        if (
            !email ||
            !password ||
            !firstName ||
            !confirmPassword ||
            !userType
        ) {
            return toast.error('Please fill all the fields');
        }

        const data = await auth.signup(
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            userType,
            subscribeCheckbox
        );

        if (!data.success) {
            return toast.error(data.message);
        }

        console.log("auth signup", data);

        console.log("signed up successfully");
        toast.success('Signed up successfully!');

        // Call the subscription API if the checkbox is checked
        if (subscribeCheckbox) {
            handleSubscription();
        }
    };

    if (auth.user) {
        return navigate('/');
    }

    return (
        <div className="signup-container">
            <div className="signup-company-name">
                <img src={logo} alt="Med Harbor" style={{width: '50px'}} />
                <header>Med Harbor</header>
            </div>

            <div className="signup-inputs">
                <div className="signup-input">
                    <img src={user_icon} alt="" style={{width: '22px'}} />
                    <input
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>
                <div className="signup-input">
                    <img src={user_icon} alt="" style={{width: '22px'}} />
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>
                <div className="signup-input">
                    <img src={email_icon} alt="" style={{width: '22px'}}  />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) =>setEmail(e.target.value)}
                    />
                </div>

                <div className="signup-input">
                    <img src={password_icon} alt="" style={{width: '22px'}} />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="signup-input">
                    <img src={password_icon} alt="" style={{width: '22px'}} />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <div className="signup-input">
                    <input
                        style={{
                            height: '15px',
                            width: '70px',
                            textAlign: 'centre',
                            verticalAlign: 'middle',
                        }}
                        type="checkbox"
                        id="Subscribecheckbox"
                        checked={subscribeCheckbox}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="subscribecheckbox">Click here to subscribe to our Newsletter</label>
                </div>
            </div>

            <div className="signup-user-type">
                <label>
                    <input
                        type="radio"
                        value="User"
                        checked={userType === 'User'}
                        onChange={handleUserTypeChange}
                    />
                    User
                </label>

                <label>
                    <input
                        type="radio"
                        value="Doctor"
                        checked={userType === 'Doctor'}
                        onChange={handleUserTypeChange}
                    />
                    Doctor
                </label>

                <label>
                    <input
                        type="radio"
                        value="Healthcare Provider"
                        checked={userType === 'Healthcare Provider'}
                        onChange={handleUserTypeChange}
                    />
                    Healthcare Provider
                </label>
            </div>

            <div className="signup-submit-container">
                <div className="signup-submit" onClick={handleSignup}>
                    Sign Up
                </div>
            </div>

            <div className="signup-already-account">
                {' '}
                Already have an account?
                <span>
                    <Link to="/signin" style={{ textDecoration: 'none' }}>
                        &nbsp; Sign In{' '}
                    </Link>
                </span>
            </div>

            <div className="signup-terms-and-policy">
                <span>
                    <a
                        href="https://www.apple.com/legal/internet-services/terms/site.html"
                        target="_blank"
                        className="siginin-terms-of-use"
                        rel="noreferrer"
                    >
                        Terms of Use
                    </a>
                </span>
                &nbsp; | &nbsp;
                <span>
                    <a
                        href="https://www.apple.com/legal/privacy/"
                        target="_blank"
                        className="siginin-privacy-policy"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                </span>
            </div>
        </div>
    );
};

export default Signup;