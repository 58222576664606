// UserContext.js
import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({
        firstName: '', // Initialize other fields if needed
        lastName: '',
        address: '',
        email: '',
        phoneNo: '',
        gender: '',
        dob: '',
    });

    const updateUserDetails = (updatedDetails) => {
        setUser((prevUser) => ({
            ...prevUser,
            ...updatedDetails,
        }));
    };

    return (
        <UserContext.Provider value={{ user, updateUserDetails }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
