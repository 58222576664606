import AddMedicationPopup from './AddMedicationPopup';
import SymptomMain from './SymptomMain';
import App2 from '../components/additionalAppointment';
import { Button, Modal, Form } from 'react-bootstrap';
import { useState } from 'react';
import classes from '../styles/CardContainer.module.css';

export default function CardsContainer() {
    const [selectedTab, setSelectedTab] = useState(null);
    const [show, setShow] = useState(false);
    const [showAddMedicationPopup, setShowAddMedicationPopup] = useState(false);
    const [showSymptomPopup, setShowSymptomPopup] = useState(false);

    function handleCloseMedicationpopUp() {
        setShowAddMedicationPopup(false);
    }

    function handleCloseSymptomPopup() {
        setShowSymptomPopup(false);
    }

    function handleOpenMedicationpopUp() {
        setShowAddMedicationPopup(true);
    }

    function handleOpenSymptomPopup() {
        setShowSymptomPopup(true);
    }

    function handleClose() {
        setShow(false);
    }

    function handleTabClick(tab) {
        console.log(tab);
        setSelectedTab(tab);
        if (tab === 'upcoming') {
            setShow(true);
        }
    }

    return (
        <>
            <div className={classes.cards}>
                <div
                    className={`${classes.card} ${classes.card1}`}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <p  style={{ marginTop: '20px' }}>
                        Add Medicine
                    </p>
                    <p className={classes.card__apply}>
                        <a className={classes.card__link_new} onClick={handleOpenMedicationpopUp}>
                            <Button variant="primary">Open</Button>
                        </a>
                    </p>
                </div>
                <div
                    className={`${classes.card} ${classes.card2}`}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <p  style={{ marginTop: '20px' }}>
                        Add Symptom
                    </p>
                    <p className={classes.card__apply}>
                        <a className={classes.card__link_new} onClick={handleOpenSymptomPopup}>
                            <Button variant="primary">Open</Button>
                        </a>
                    </p>
                </div>
                <div
                    onClick={() => handleTabClick('upcoming')}
                    className={`${classes.card} ${classes.card3}`}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <p style={{ marginTop: '20px' }}>
                        Upcoming Appointments
                    </p>
                    <p className={classes.card__apply}>
                        <a className={classes.card__link_new}>
                            <Button variant="primary">Open</Button>
                        </a>
                        {showAddMedicationPopup}
                    </p>
                </div>
                {showAddMedicationPopup && (
                    <AddMedicationPopup
                        show={showAddMedicationPopup}
                        handleCloseMedicationpopUp={handleCloseMedicationpopUp}
                    />
                )}
                {showSymptomPopup && (
                    <SymptomMain
                        show={showSymptomPopup}
                        handleCloseSymptomPopup={handleCloseSymptomPopup}
                    />
                )}
                {selectedTab && selectedTab === 'upcoming' && (
                    <>
                        <Modal show={show} size="lg" onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Upcoming Appointments</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <App2 />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </div>
        </>
    );
}
