import SymptomMain from '../components/SymptomMain';
import { AddMedicationPopup, NL } from '../components';
import CardsContainer from '../components/CardsContainer';
import HeaderSection from '../components/HeaderSection';
import App2 from '../components/additionalAppointment';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import image1 from '../Assets/carousel-1.jpg'
import image2 from '../Assets/carousel-3.jpg'
import image3 from '../Assets/carousel-2.jpeg'
import image4 from '../Assets/carousel-4.jpg'
import '../styles/Home.css';
import Modal from 'react-bootstrap/Modal';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../providers';
import Offcanvas from 'react-bootstrap/Offcanvas';
import GalleryComponent from '../components/galleryComponent';
import Col from 'react-bootstrap/Col';
import meetTheTeam1 from '../Assets/meetTheTeam-1.png';
import meetTheTeam2 from '../Assets/meetTheTeam-2.png';
import meetTheTeam3 from '../Assets/meetTheTeam-3.png';

export default function Home() {
  const auth = useAuth();
  const [selectedTab, setSelectedTab] = useState(null);
  const [show, setShow] = useState(false)
  const [hovered, setHovered] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [email, setEmail] = useState('');
  const [showMeetTheteam, setShowMeetTheTeam] = useState(false);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
   // fetchRecordData('upcomingAppointments');
    setShow(true);
  };

  function handleClose(){
    setShow(false);
  }

  function handleCanvasClose(){
    setShowCanvas(false);
  }

  function handleCanvasShow(){
    setShowCanvas(true);
  }

  const handleMeetTheTeam = (event)=>{
   console.log('event created', event);
   setShowMeetTheTeam(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
    if (!emailRegex.test(email)) {
      console.error('Invalid email format');
      setSubscriptionError(true);
      setSubscriptionSuccess(false);
      return;
    }

    try {
      const response = await fetch('https://medharbor-backend.onrender.com/newsletters', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        console.log('Subscription successful');
        setSubscriptionSuccess(true);
        setSubscriptionError(false); 
        setEmail(''); 
      } else {
        console.error('Subscription failed');
        setSubscriptionSuccess(false); 
        setSubscriptionError(true);
      }
    } catch (error) {
      console.error('Network error:', error);
      setSubscriptionSuccess(false); 
      setSubscriptionError(true);
    }
  };

  
  return (
      <div className="mt-3">
          <div id="home-page">
              <Row
                  className="carousel_container"
                  style={{ marginLeft: '20px', marginRight: '20px', width:'97%' }}
              >
                  <HeaderSection  subscribeClicked =  {handleCanvasShow}/>
              </Row>
              <Row className="fadeInUp-animation"  style={{ marginLeft: '20px', marginRight: '20px' }}>
                  <CardsContainer />
              </Row>
              <Row  style={{ marginLeft: '20px', marginRight: '20px' }}>
                  <GalleryComponent />
              </Row>
              <div className="newletterSection mt-3">
                  <NL pageType="home" />
              </div>
              <Footer meettheTeamClicked={handleMeetTheTeam}></Footer>
              <Offcanvas
                  show={showCanvas}
                  onHide={handleCanvasClose}
                  placement={'bottom'}
                  name={'bottom'}
              >
                  <Offcanvas.Header closeButton></Offcanvas.Header>
                  <Offcanvas.Body>
                      <div className="container">
                          {subscriptionSuccess && (
                              <div className="alert alert-success" role="alert">
                                  Success! Thank you for subscribing.
                              </div>
                          )}
                          {subscriptionError && (
                              <div className="alert alert-danger" role="alert">
                                  Sorry! The subscription has failed. Please
                                  enter a valid email.
                              </div>
                          )}
                          {!subscriptionSuccess && !subscriptionError && (
                              <div
                                  style={{ backgroundColor: '#F5F7F8' }}
                                  className=" rounded d-flex flex-column justify-content-between align-items-center flex-lg-row p-4 p-lg-5"
                              >
                                  <div className="text-center text-black text-lg-start  py-lg-1">
                                      <h2 className="fw-bold mb-2">
                                          Subscribe to our Newsletter
                                      </h2>
                                  </div>
                                  <form
                                      className="d-flex justify-content-center flex-wrap flex-lg-nowrap"
                                      onSubmit={handleSubmit}
                                  >
                                      <div className="my-2">
                                          <input
                                              className="border rounded-pill shadow-sm form-control"
                                              type="email"
                                              name="email"
                                              value={email}
                                              onChange={(e) =>
                                                  setEmail(e.target.value)
                                              }
                                              placeholder="Your Email"
                                          />
                                      </div>
                                      <div className="my-2">
                                          <button
                                              className="btn btn-primary shadow ms-2"
                                              type="submit"
                                          >
                                              Subscribe
                                          </button>
                                      </div>
                                  </form>
                              </div>
                          )}
                      </div>
                  </Offcanvas.Body>
              </Offcanvas>
          
          </div>
          <Modal className='meet_the_team_modal'
              show={showMeetTheteam}
              aria-labelledby="example-custom-modal-styling-title"
              style={{ '--bs-modal-width': '1100px', display:'flex !important',  alignItems: 'center',
                justifyContent: 'center',  marginLeft:'40px', marginRight:'auto' }}
              onHide={() => setShowMeetTheTeam(false)}
          >
              <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                      Meet The Team
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div style={{ textAlign: 'center', padding:'14px' }}>
                      <p>
                          Our team is driven by a commitment to excellence,
                          collaboration, and innovation. We believe in the power
                          of diverse perspectives and are dedicated to creating
                          solutions that make a difference.
                      </p>
                  </div>
                  <Container fluid style={{ padding: '20px' }}>
                      <Row>
                          <Col>
                              <div
                                  style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                  }}
                              >
                                  <img
                                      src={meetTheTeam1}
                                      alt="gallary"
                                      style={{
                                          width: '300px',
                                          height: '300px',
                                      }}
                                  />
                                  <h5 style={{ marginTop: '5px' }}>
                                      Bryan Yavari
                                  </h5>
                                  <p>
                                      Founder, Chief Executive Officer, and
                                      Software Engineer
                                  </p>
                              </div>
                          </Col>
                          <Col>
                              <div
                                  style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                  }}
                              >
                                  <img
                                      src={meetTheTeam2}
                                      alt="gallary"
                                      style={{
                                          width: '300px',
                                          height: '300px',
                                      }}
                                  />
                                  <h5 style={{ marginTop: '5px' }}>
                                      Omkaar Shenoy{' '}
                                  </h5>
                                  <p>
                                      {' '}
                                      Chief Operating Officer and Software
                                      Engineer
                                  </p>
                              </div>
                          </Col>
                          <Col>
                              <div
                                  style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                  }}
                              >
                                  <img
                                      src={meetTheTeam3}
                                      alt="gallary"
                                      style={{
                                          width: '300px',
                                          height: '300px',
                                      }}
                                  />
                                  <h5 style={{ marginTop: '5px' }}>
                                      Roxana Namvari
                                  </h5>
                                  <p>Operations Manager</p>
                              </div>
                          </Col>
                      </Row>
                  </Container>
              </Modal.Body>
          </Modal>
      </div>
      
  );


}
