import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../providers';
import '../styles/Navbar.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import MedharborLogo from '../Assets/medharbor_logo_white.png';

export default function Navbar() {
    const auth = useAuth();
    const [isAuthenticated, setIsAuthenticated] = useState(auth.user !== null);
    const navCollapseRef = useRef(null);


    const handleSignOut = () => {
        auth.signout();
        setIsAuthenticated(false); // Update authentication status
        toggleNavbar();
    };

    const toggleNavbar = () => {
        if (navCollapseRef.current.classList.contains('show')) {
            navCollapseRef.current.classList.remove('show');
        }
    };

    return (
        <nav
        className="navbar navbar-light navbar-expand-md py-3 fixed-top"
            id="mainNav"
            style={{ backgroundColor: '#2D2C38' }}
        >
            <div className="container">
                <a className="navbar-brand d-flex align-items-center" href="/">
                    <span className="bs-icon-sm bs-icon-circle bs-icon-primary shadow d-flex justify-content-center align-items-center me-2 bs-icon">
                        <img src={MedharborLogo} alt="logo" style={{ width: '25px', height: '25px' }} />
                    </span>
                    <span className="text-white">Med Harbor</span>
                </a>
                <button
                    data-bs-toggle="collapse"
                    className="navbar-toggler bg-white"
                    data-bs-target="#navcol-1"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navcol-1" ref={navCollapseRef}>
                    <ul className="navbar-nav mx-auto" />
                    <NavLink
                        className="nav-link"
                        to="/"
                        style={({ isActive }) => ({
                            color: isActive ? '#fff' : '#a6b0bf',
                            paddingRight: 30,
                        })}
                        onClick={toggleNavbar}
                    >
                        {' '}
                        Home
                    </NavLink>
                    <NavLink
                        className="nav-link"
                        to="/medication"
                        style={({ isActive }) => ({
                            color: isActive ? '#fff' : '#a6b0bf',
                            fontWeight: isActive ? 'bold': 'normal',
                            paddingRight: 30,
                        })}
                        onClick={toggleNavbar}
                    >
                        Medication
                    </NavLink>
                    <NavLink
                        className="nav-link"
                        to="/symptomtracker"
                        style={({ isActive }) => ({
                            color: isActive ? '#fff' : '#a6b0bf',
                            fontWeight: isActive ? 'bold': 'normal',
                            paddingRight: 30,
                        })}
                        onClick={toggleNavbar}
                    >
                        Symptom Tracker
                    </NavLink>
                    <NavLink
                        className="nav-link"
                        to="/myrecords"
                        style={({ isActive }) => ({
                            color: isActive ? '#fff' : '#a6b0bf',
                            fontWeight: isActive ? 'bold': 'normal',
                            paddingRight: 30,
                        })}
                        onClick={toggleNavbar}
                    >
                        My Records
                    </NavLink>
                    <NavLink
                        className="nav-link"
                        to="/newsletter"
                        style={({ isActive }) => ({
                            color: isActive ? '#fff' : '#a6b0bf',
                            fontWeight: isActive ? 'bold': 'normal',
                            paddingRight: 30,
                        })}
                        onClick={toggleNavbar}
                    >
                        Newsletter
                    </NavLink>

                    <NavLink
                        className="nav-link"
                        to="/fitness"
                        style={({ isActive }) => ({
                            color: isActive ? '#fff' : '#a6b0bf',
                            fontWeight: isActive ? 'bold': 'normal',
                            paddingRight: 30,
                        })}
                        onClick={toggleNavbar}
                    >
                        Fitness
                    </NavLink>

                    {auth.user ? (
                        <>
                            <NavDropdown
                                title={auth.user.firstName}
                                id="collapsible-nav-dropdown"
                                style={{
                                    color: '#a6b0bf', 
                                    paddingRight: 30,
                                    textDecoration: 'none',
                                }}
                            >
                                <NavDropdown.Item>
                                    <NavLink to="/ProfileDetails" style={{textDecoration: 'none',color: 'black'}}>
                                        My Profile
                                    </NavLink>
                                </NavDropdown.Item>
                                
                                <NavDropdown.Item>
                                    <NavLink 
                                        className="nav-link"
                                        style={({ isActive }) => ({
                                            color: isActive ? 'black' : 'black',
                                            paddingRight: 30,
                                            textDecoration: 'none',
                                        })}
                                        onClick={handleSignOut}
                                    >
                                        Sign out
                                    </NavLink>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </>
                    ) : (
                        <NavLink
                            to="/signin"
                            style={({ isActive }) => ({
                                color: isActive ? '#fff' : '#a6b0bf',
                                paddingRight: 30,
                                textDecoration: 'none',
                            })}
                            onClick={toggleNavbar}
                        >
                            Sign In
                        </NavLink>
                    )}
                </div>
            </div>
        </nav>
    );

  
}
