import React, { useState, useEffect } from 'react';
import '../styles/slider.css';

const Slider = (props) => {
    const [value, setValue] = useState(props.initialPainLevel || 0);
    const [isSliderVisible, setIsSliderVisible] = useState(props.initialIsChronic || false);


    useEffect(() => {
        setIsSliderVisible(props.initialIsChronic || false);
        setValue(props.initialPainLevel || 0);
      }, [props.initialIsChronic, props.initialPainLevel]);

    const handleSliderChange = (event) => {
        setValue(event.target.value);
        props.onPainLevelChanged(event.target.value)
    };

    const handleCheckboxChange = () => {
        setIsSliderVisible(!isSliderVisible);
        if (!isSliderVisible) {
            //setValue(0);
            props.onPainLevelChanged(value); // 0
        } else {
            props.onPainLevelChanged(undefined);
        }
        props.onChronicChanged(!isSliderVisible)
    };

    const getColor = (value) => {
        if(value == 0 ) return 'green'
        if (value < 3) return 'yellow';
        if (value < 5) return 'yellow';
        if (value < 7) return 'orange';
        return 'red';
    };

    return (
        <div className="slider-container-pain">
            <h6>Pain</h6>
            <span className="chronic-container">
                <p style={{ marginTop: '-5px', position:'relative'}}>
                    Chronic ?{' '}
                    <input
                        style={{
                            height: '19px',
                            width: '19px',
                            marginLeft: '10px',
                            position:'absolute',
                            marginTop: '-4px'
                        }}
                        type="checkbox"
                        checked={isSliderVisible}
                        onChange={handleCheckboxChange}
                    />
                </p>
            </span>
            {isSliderVisible && (
                <>
                    <input
                        type="range"
                        min="0"
                        max="10"
                        value={value}
                        onChange={handleSliderChange}
                        className="slider"
                        style={{ background: getColor(value) }}
                    />
                    <div
                        className="slider-value"
                        style={{ color: getColor(value), display: 'flex' }}
                    >
                        <p>Pain Level</p>{' '}
                        <div style={{ marginLeft: '10px' }}> {value} </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Slider;
