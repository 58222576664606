import image1 from '../Assets/carousel-1.jpg';
import image3 from '../Assets/carousel-3.jpg';
import classes from '../styles/headerSection.module.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../providers';
import { Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'


function Header(props) {
    const auth = useAuth();

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    function subscribeClicked(){
       props.subscribeClicked(true);
    }
    return (
        <header>
            <div className={`${classes.section__container} ${classes.header__container} header-padding`}>
                <div className={classes.header__image} style={{ marginLeft: isMobile ? '0px' : '90px' }}>
                    <img
                        src={image3}
                        style={{ height: '400px', width: '500px' }}
                        alt="header"
                    />
                    <img src={image1} alt="header" />
                </div>
                <div className={classes.header__content} style={{ marginLeft: isMobile ? '0px' : '115px' }}>
                    <div style={{width: '75%'}}>
                        <p className={classes.sub__header} style={{ textAlign: isMobile ? 'center' : '' }}>About us</p>
                        <h1 style={{ textAlign: isMobile ? 'center': ''}}>
                            Welcome to
                            <br />
                            Med Harbor
                        </h1>
                        <p className={classes.section__subtitle}>
                            Your premier destination for personalized Electronic Medical Records. Our platform offers a customized
                            experience, allowing you to securely manage your health information in one central location — completely free of charge. Stay informed with
                            evidence-based newsletters written by experienced doctors, delivering reliable and up-to-date medical insights directly to your inbox.
                        </p>
                        <div className={classes.action__btns}>
                            <button
                                className={classes.btn}
                                onClick={subscribeClicked}
                            >
                                Subscribe
                            </button>
                            {(!auth || !auth.user) && (
                                <div className={classes.story}>
                                    <Link to="/signup">
                                        <button
                                            className={classes.btn}
                                            onClick={subscribeClicked}
                                        >
                                            Sign Up
                                        </button>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
