const API_ROOT = 'http://localhost:8080';

export const LOCALSTORAGE_TOKEN_KEY = '__medharbor_token__';

export const API_URLS = {
    signin: () => `${API_ROOT}/auth/signin`,
    signup: () => `${API_ROOT}/auth/register`,
    updatePassword: () => `${API_ROOT}/auth/update`,
    updateUserDetails: () => `${API_ROOT}/auth/profileupdate`
};
