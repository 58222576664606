import React, { useState } from 'react';
import '../styles/Signin.css';
import email_icon from '../Assets/email.png';
import logo from '../Assets/icons8-medical-50.png';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

function Reset() {
    //const API_URLS = "https://medharbor-wd6d.onrender.com/resetPassword";
    const API_URLS = "https://medharbor-backend.onrender.com/resetPassword";
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();

    const resetUser = async() => {
            if (email) {
                const generate_otp = Math.floor(Math.random() * 9000 + 1000);
                console.log(generate_otp);
                setOtp(generate_otp);
                try{
                    const req = await fetch(API_URLS, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email, otp: generate_otp}),
                    });
                    console.log(req, req.status);
                }catch(e){
                    console.log(e.error);
                }
                navigate('/otp', { state: { email, otp: generate_otp } });
            } else {
                toast.error("Please enter your email");
            }
    }

    return (
            <div className="siginin-container">
                <div className="siginin-company-name">
                    <img src={logo} alt="Med Harbor" style={{width: '40px'}}/>
                    <header>Med Harbor</header>
                </div>

                <h3 style={{marginTop: 50, textAlign: 'center'}}>Forgot password</h3>
                
                <div className="siginin-inputs">
                    <div className="siginin-input">
                        <img src={email_icon} alt="" style={{width: '22px'}}/>
                        <input
                            type="username"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>

                <p style={{marginTop: 50, textAlign: 'center', color: 'grey'}}>We’ll send a verification code to this email if it matches an existing Med Harbor account.</p>

                <div className="siginin-submit-container">
                    <div className="siginin-submit" onClick={resetUser}>
                        Next
                    </div>
                </div>
            </div>
    );
}

export default Reset;
