import React, { useState } from 'react';
import '../styles/NewsletterForm.css'; // Import the CSS file


function NewsletterForm() {

  const [formData, setFormData] = useState({
    title: '',
    author: '',
    authorWork: '',
    article: '',
    reference: '',
  });

  const [submissionStatus, setSubmissionStatus] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.title || !formData.article || !formData.author) {
      setSubmissionStatus('failure');
      return;
    }

    const username = prompt('Enter your username:');
    const password = prompt('Enter your password:');
    const credentials = `${username}:${password}`;
    const encodedCredentials = btoa(credentials);
    const headers = new Headers();
    headers.set('Authorization', `Basic ${encodedCredentials}`);
    headers.set('Content-Type', 'application/json');
    if (encodedCredentials==="QnJ5YW55YXZhcmlAaWNsb3VkLmNvbTpNZWRoYXJib3IxMiE="){
      try {
        const response = await fetch("https://medharbor-backend.onrender.com/newsletters/", {
          method: "POST",
          headers: headers,
          body: JSON.stringify(formData),
        });
  
        if (!response.ok) {
          throw new Error('Failed to create newsletter');
        }
  
        setSubmissionStatus('success');
        setFormData({
          title: '',
          author: '',
          authorWork: '',
          article: '',
          reference: '',
        });
      } catch (error) {
        setSubmissionStatus('failure');
        console.error("Error creating newsletter:", error.message);
      }
    }else{
      setSubmissionStatus('permission');
      console.error("You do not have permissions to send newsletter");
    }
    
  };

  return (
    <div className="newsletter-form-container">
      <h1 className="newsletter-form-title">Create Newsletter</h1>
      {submissionStatus === 'success' && <p className="newsletter-success-message">Newsletter created successfully!</p>}
      {submissionStatus === 'failure' && <p className="newsletter-failure-message">Failed to create the Newsletter!</p>}
      {submissionStatus === 'permission' && <p className="newsletter-failure-message">You do not have the permission to send Newsletters</p>}
      <form className="newsletter-form" onSubmit={handleSubmit} noValidate>
        <label className="newsletter-form-label" htmlFor="title">Title:</label><br />
        <input 
          className="newsletter-form-input" 
          type="text" 
          id="title" 
          name="title" 
          value={formData.title} 
          onChange={handleChange} 
          required 
        /><br />
        <label className="newsletter-form-label" htmlFor="author">Author:</label><br />
        <input 
          className="newsletter-form-input" 
          type="text" 
          id="author" 
          name="author" 
          value={formData.author} 
          onChange={handleChange} 
          required 
        /><br />
        <label className="newsletter-form-label" htmlFor="authorWork">Description for Author:</label><br />
        <input 
          className="newsletter-form-input" 
          type="text" 
          id="authorWork" 
          name="authorWork" 
          value={formData.authorWork} 
          onChange={handleChange} 
          required 
        /><br />
        <label className="newsletter-form-label" htmlFor="article">Article:</label><br />
        <textarea 
          className="newsletter-form-textarea" 
          id="article" 
          name="article" 
          rows="4" 
          value={formData.article} 
          onChange={handleChange} 
          required 
        ></textarea><br />
        <label className="newsletter-form-label" htmlFor="reference">Reference:</label><br />
        <input
          className="newsletter-form-input"
          type="text"
          id="reference" 
          name="reference" 
          value={formData.reference} 
          onChange={handleChange} 
          required 
        /><br />
        <button className="newsletter-form-button" type="submit">Submit</button>
      </form>
    </div>
  );
}

export default NewsletterForm;
