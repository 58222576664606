import '../styles/Fitness.css';
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import { useAuth } from '../providers';
import { Accordion } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Modal } from 'react-bootstrap'; 
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
// Components
import DateInput from '../components/DateInput';
import HealthQuote from '../components/HealthQuote';
import GoalInputs from '../components/GoalInput';
import WaterRangeSlider from '../components/WaterRangeSlider';
import MealCalorie from '../components/MealCalorie';
import WaterProgressBar from '../components/WaterProgressBar';
import CaloriesConsumeProgressBar from '../components/CaloriesConsumeProgressBar';
import CaloriesBurnProgressBar from '../components/CaloriesBurnProgressBar';
import SleepRangeSlider from '../components/SleepRangeSlider';
import StressRangeSlider from '../components/StressRangeSlider';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function Fitness() {
    const auth = useAuth();
    const user_id = auth.user?.id;
    // const backend_url = "http://localhost:4000/"
    const backend_url = "https://medharbor-backend.onrender.com/";

    const [selectedDate, setSelectedDate] = useState('');
    const [waterGoal, setWaterGoal] = useState('0');
    const [caloriesConsumeGoal, setCaloriesConsumeGoal] = useState('0');
    const [caloriesBurnGoal, setCaloriesBurnGoal] = useState('0');
    const [goal, setGoal] = useState(null);
    const [water, setWater] = useState(0);
    const [sleep, setSleep] = useState(0);  
    const [stress, setStress] = useState(0);
    const [meals, setMeals] = useState([]);
    const [caloriesBurn, setCaloriesBurn] = useState(0);
    const [goalsEnabled, setGoalsEnabled] = useState(false);
    const [workoutEntries, setWorkoutEntries] = useState([]);
    const [showGraph, setShowGraph] = useState(false);
    const [goalData, setGoalData] = useState([]);
    const [caloriesData, setCaloriesData] = useState(null);
    // Handle Date Change
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setGoalsEnabled(true);
        fetchGoalByDate(date);
        fetchWaterByDate(date);
        fetchSleepByDate(date);
        fetchStressByDate(date);
        fetchMealsByDate(date);
        fetchWorkoutByDate(date);
    };

    // Handle Water Goal Change
    const handleWaterGoalChange = (waterGoal) => {
        setWaterGoal(waterGoal);
    };

    // Handle Calories Consume Goal Change
    const handleCaloriesConsumeGoalChange = (caloriesConsumeGoal) => {
        setCaloriesConsumeGoal(caloriesConsumeGoal);
    };

    // Handle Calories Burn Goal Change
    const handleCaloriesBurnGoalChange = (caloriesBurnGoal) => {
        setCaloriesBurnGoal(caloriesBurnGoal);
    };

    // Handle Submit Goals
    const handleSubmitGoals = async () => {
        const jsonGoalData = {
            date: selectedDate,
            water: parseInt(waterGoal),
            calories_consume: parseInt(caloriesConsumeGoal),
            calories_burn: parseInt(caloriesBurnGoal),
            creator: user_id,
        };

        try {
            const response = await axios.post(`${backend_url}goals`, jsonGoalData);
            setGoal(response.data); // Update the displayed goal
        } catch (error) {
            console.error('Error submitting goal:', error.message);
        }
    };

    // Fetch Goal by Date
    const fetchGoalByDate = async (targetDate) => {
        try {
            const response = await axios.get(`${backend_url}goals/${user_id}?date=${targetDate}`);
            if (response.data.length > 0) {
                const fetchedGoal = response.data[0];
                setGoal(fetchedGoal);
                setWaterGoal(fetchedGoal.water.toString());
                setCaloriesConsumeGoal(fetchedGoal.calories_consume.toString());
                setCaloriesBurnGoal(fetchedGoal.calories_burn.toString());
            } else {
                setGoal(null);
                setWaterGoal('0');
                setCaloriesConsumeGoal('0');
                setCaloriesBurnGoal('0');
            }
        } catch (error) {
            console.error('Error fetching goal:', error.message);
        }
    };

    // Handle Water Submission
    const handleSubmitWater = async (amount) => {
        const jsonWaterData = {
            date: selectedDate,
            amount,
            creator: user_id,
        };

        try {
            const response = await axios.post(`${backend_url}water`, jsonWaterData);
            setWater(response.data.amount);
            fetchWaterByDate(selectedDate); // Fetch the updated water data
        } catch (error) {
            console.error('Error submitting water:', error.message);
        }
    };

    //Handle Sleep Submission
    const handleSubmitSleep = async (amount) => {
        const jsonSleepData = {
            date: selectedDate,
            amount,
            creator: user_id
        };
        try {
            const response = await axios.post(`${backend_url}sleep`, jsonSleepData);
            setSleep(response.data.amount);
            fetchSleepByDate(selectedDate); //Fetch the updated sleep data
        } catch(error) {
            console.error('Error submitting sleep:', error.message)
        }
    }

    //Handle Stress Submission
    const handleSubmitStress = async (amount) => {
        const jsonStressData = {
            date: selectedDate,
            amount,
            creator: user_id
        };

        try {
            const response = await axios.post(`${backend_url}stress`, jsonStressData);
            setStress(response.data.amount);
            fetchStressByDate(selectedDate); //Fetch the updated sleep data
        } catch(error) {
            console.error('Error submitting stress:', error.message)
        }
    }

    //Fetch Stress by Date
    const fetchStressByDate = async (targetDate) => {
        try {
            const response = await axios.get(`${backend_url}stress/${user_id}?date=${targetDate}`);
            if (response.data.length > 0) {
                setStress(response.data[0].amount);
            } else {
                setStress(0);
            }
        } catch (error) {
            console.error('Error fetching stress:', error.message);
        }
    };

    // Fetch Sleep by Date
    const fetchSleepByDate = async (targetDate) => {
        try {
            const response = await axios.get(`${backend_url}sleep/${user_id}?date=${targetDate}`);
            if (response.data.length > 0) {
                setSleep(response.data[0].amount);
            } else {
                setSleep(0);
            }
        } catch (error) {
            console.error('Error fetching sleep:', error.message);
        }
    };

    // Fetch Water by Date
    const fetchWaterByDate = async (targetDate) => {
        try {
            const response = await axios.get(`${backend_url}water/${user_id}?date=${targetDate}`);
            if (response.data.length > 0) {
                setWater(response.data[0].amount);
            } else {
                setWater(0);
            }
        } catch (error) {
            console.error('Error fetching water:', error.message);
        }
    };

    // Fetch Meals by Date
    const fetchMealsByDate = async (targetDate) => {
        try {
            const response = await axios.get(`${backend_url}meals/${user_id}?date=${targetDate}`);
            if (response.data.length > 0) {
                setMeals(response.data);
            } else {
                setMeals([]);
            }
        } catch (error) {
            console.error('Error fetching meals:', error.message);
        }
    };

    //Fetch workout by Date
    const fetchWorkoutByDate = async (targetDate) => {
        try {
            const response = await axios.get(`${backend_url}workout/${user_id}?date=${targetDate}`);
            if (response.data.length > 0) {
                // Assuming response.data is an array of workout entries
                const workouts = response.data;
                setWorkoutEntries(workouts);
                const totalCaloriesBurnt = workouts.reduce((total, workout) => total + workout.calories, 0);
                setCaloriesBurn(totalCaloriesBurnt);
            } else {
                setCaloriesBurn(0);
                setWorkoutEntries([]);
            }
        } catch (error) {
            console.error('Error fetching workout:', error.message)
        }
    };

    // Handle Submit Meal
    const handleSubmitMeal = async (mealData) => {
        const jsonMealData = {
            ...mealData,
            date: selectedDate,
            creator: user_id,
        };

        try {
            const response = await axios.post(`${backend_url}meals`, jsonMealData);
            console.log(...meals, response,"meal submission data")
            setMeals([...meals, response.data]);
            fetchMealsByDate(selectedDate); // Fetch the updated meals data
        } catch (error) {
            console.error('Error submitting meal:', error.message);
        }
    };

     // Handle Graph Modal Open/Close
     const handleShowGraph = async () => {
        setShowGraph(!showGraph);

        try {
            //const response = await axios.get(`${backend_url}workout/123`);
            const response = await axios.get(`${backend_url}goals/${user_id}`);
            if (response.data.length > 0) {
                // Store the fetched goal data
                setGoalData(response.data);

                // Prepare chart data based on the schema structure
                const chartData = {
                    labels: response.data.map((entry) => 
                        new Date(entry.date).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        })
                    ), // Mapping dates and formatting them
                    datasets: [
                        {
                            label: 'Calories Consumed',
                            data: response.data.map((entry) => entry.calories_consume), // Mapping calories consumed
                            fill: false,
                            borderColor: 'rgb(75, 192, 192)',
                            tension: 0.1,
                        },
                        {
                            label: 'Calories Burned',
                            data: response.data.map((entry) => entry.calories_burn), // Mapping calories burned
                            fill: false,
                            borderColor: 'rgb(255, 99, 132)',
                            tension: 0.1,
                        }
                    ]
                };

                // Set chart data for the graph
                setCaloriesData(chartData);

            } else {
                // Handle case where no data is returned
                setGoalData([]);
            }
        } catch (error) {
            console.error('Error fetching workout data:', error.message);
        }
    };
    



    const [selectedMuscleGroup, setSelectedMuscleGroup] = useState('');
    const [selectedExercise, setSelectedExercise] = useState('');
    const [caloriesBurnt, setCaloriesBurnt] = useState('');

    const muscleGroups = {
        'Chest': ['Push-Ups', 'Bench Press', 'Flyes', 'Other'],
        'Back': ['Pull ups', 'Rows', 'Deadlifts', 'Other'],
        'Legs': ['Squats', 'Lunges', 'Leg Press', 'Other'],
        'Biceps' :['Push-Ups', 'Pull-Ups', 'Bicep Curls','Other'],
        'Triceps' :['Tricep Kikcbacks', 'Push ups', 'Other'],
        'Cardio' :['Squats', 'Jumping Jacks', 'Burpees', 'Other'],
        'Shoulders' :['Front Raise', 'lateral Raise', 'Reverse Fly', 'Other']
      };
      
    const handleMuscleGroupChange = (event) => {
        setSelectedMuscleGroup(event.target.value);
        setSelectedExercise(''); 
    };
      
    const handleExerciseChange = (event) => {
        setSelectedExercise(event.target.value);
    };

    const handleCaloriesChange = (event) => {
        setCaloriesBurnt(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const workoutData = {
          muscleGroup: selectedMuscleGroup,
          exercise: selectedExercise,
          calories: parseInt(caloriesBurnt, 10),
          creator: user_id, // Include the user ID
          date: selectedDate // User-provided date
        };
        console.log(workoutData, "before sending workout data")
        try {
          const response = await axios.post(`${backend_url}workout`, workoutData); 
          console.log('Workout saved:', response.data);
          fetchWorkoutByDate(selectedDate)
          setSelectedMuscleGroup('');
          setSelectedExercise('');
          setCaloriesBurnt('');
        } catch (error) {
          console.error('Error saving workout:', error);
        }
      };

    
    
    return (
        <Container id="fitness-page">
            <Row >
                <div class="quote">
                    {/* Health Quote */}
                <HealthQuote />
                </div>
            </Row>
            
            <div className="fitness-container">
                <Card className="fitness-card">
                    <Card.Header as="h4">Date & Goals</Card.Header>
                    <Card.Body>
                        <DateInput onDateChange={handleDateChange} />
                        <Form>
                            <GoalInputs
                                enabled={goalsEnabled}
                                onWaterGoalChange={handleWaterGoalChange}
                                onCaloriesConsumeGoalChange={handleCaloriesConsumeGoalChange}
                                onCaloriesBurnGoalChange={handleCaloriesBurnGoalChange}
                                waterGoal={waterGoal}
                                caloriesConsumeGoal={caloriesConsumeGoal}
                                caloriesBurnGoal={caloriesBurnGoal}
                            />
                            <div className="button-container">
                                <Button variant="primary" onClick={handleSubmitGoals} disabled={!goalsEnabled}>Submit</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>

                <Card className="fitness-card">
                    <Card.Header as="h4">Progress</Card.Header>
                    <Card.Body>
                        <WaterProgressBar currentWater={water} goalWater={parseInt(waterGoal) || 0} disabled={!goalsEnabled} />
                        <CaloriesConsumeProgressBar meals={meals} goalCalories={parseInt(caloriesConsumeGoal) || 0} disabled={!goalsEnabled} />
                        <CaloriesBurnProgressBar totalCaloriesBurn={caloriesBurn} goalCaloriesBurn={parseInt(caloriesBurnGoal) || 0} disabled={!goalsEnabled} />

                        {/* Calorie Tracker Button */}
                        <div className="graph-button-container">
                            <Button variant="secondary" onClick={handleShowGraph}>
                                Calorie Tracker
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
                <Modal show={showGraph} onHide={handleShowGraph}>
                <Modal.Header closeButton>
                    <Modal.Title>Calorie Tracker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Pass the chart data to the Line component if caloriesData exists */}
                    {caloriesData && <Line data={caloriesData} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleShowGraph}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            

                <Card className="fitness-card">
                        <Card.Header as="h4" className="text-bg-warning bg-opacity-50">Summary</Card.Header>
                        <Card.Body>
                            <Accordion>
                                {/* Goal Summary */}
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Goal Summary</Accordion.Header>
                                    <Accordion.Body className='accordion-body'>
                                        {goal ? (
                                            <div>
                                                <p>Water Goal: {goal.water || 0}  ml</p>
                                                <p>Calories to Consume Goal: {goal.calories_consume || 0}  Cal</p>
                                                <p>Calories to Burn Goal: {goal.calories_burn || 0}  Cal</p>
                                            </div>
                                        ): (
                                            <p>No goals recorded.</p>
                                            )}
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* Meals Summary */}
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Meals Summary</Accordion.Header>
                                    <Accordion.Body className='accordion-body'>
                                        {meals.length>0 ? <ul>
                                            {meals.map((meal, index) => (
                                                <li key={index}>{meal.type} - {meal.mealName} - {meal.calories} Cal</li>
                                            ))}
                                        </ul>: (
                                            <p>No meals intake recorded.</p>
                                            )}
                                        
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* Workout Summary */}
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Workout Summary</Accordion.Header>
                                    <Accordion.Body className='accordion-body'>
                                        {workoutEntries.length > 0 ? (
                                            <ul>{workoutEntries.map((workout, index) => (
                                                <li key={index}>{workout.muscleGroup} - {workout.exercise} - {workout.calories} Cal burned</li>
                                            ))}
                                            </ul>
                                            ) : (
                                            <p>No workouts recorded.</p>
                                            )}
                                    </Accordion.Body>
                                </Accordion.Item>
                                
                                {/* Water Intake Summary */}
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Water Intake Summary</Accordion.Header>
                                    <Accordion.Body className='accordion-body'>
                                    {water > 0 ? (
                                        <p>You have consumed {water} L of water today. Your goal is {waterGoal} L.</p>
                                    ) : (
                                    <p>No water intake recorded.</p>
                                    )}
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* Sleep Intake Summary */}
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Sleep Summary</Accordion.Header>
                                    <Accordion.Body className='accordion-body'>
                                    {sleep > 1 ? (
                                        <p>You have slept {sleep} hrs today.</p>
                                    ) : sleep === 1 ? (<p>You have slept {sleep} hr today</p>)
                                    : (
                                    <p>No sleep intake recorded.</p>)
                                    }
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* Stress Summary */}
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Stress Summary</Accordion.Header>
                                    <Accordion.Body className='accordion-body'>
                                    {stress > 0 ? (
                                        <p>Your stress level is {stress} today.</p>
                                    ) : (
                                    <p>No stress recorded.</p>
                                    )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Card.Body>
                    </Card>
                <Card className="fitness-card border-green">
                    <Card.Header as="h4">Meals</Card.Header>
                    <Card.Body>
                        <MealCalorie date={selectedDate} onSubmit={handleSubmitMeal} disabled={!goalsEnabled} />
                    </Card.Body>
                </Card>

                <Card className="fitness-card border-blue">
                    <Card.Header as="h4">Water, Sleep & Stress</Card.Header>
                    <Card.Body>
                        <WaterRangeSlider water={water} date={selectedDate} onSubmit={handleSubmitWater} disabled={!goalsEnabled} />
                        <SleepRangeSlider sleep={sleep} date={selectedDate} onSubmit={handleSubmitSleep} disabled={!goalsEnabled} />
                        <StressRangeSlider stress={stress} date={selectedDate} onSubmit={handleSubmitStress} disabled={!goalsEnabled} />
                    </Card.Body>
                </Card>

                <Card className="fitness-card border-pink">
                    <Card.Header as="h4">Exercise</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="muscleGroupSelect">
                            <Form.Label>Select Muscle Group</Form.Label>
                            <Form.Control as="select" value={selectedMuscleGroup} onChange={handleMuscleGroupChange}>
                            <option value="">-- Select Muscle Group --</option>
                            {Object.keys(muscleGroups).map((group) => (
                                <option key={group} value={group}>{group}</option>
                            ))}
                            </Form.Control>
                        </Form.Group>

                        {selectedMuscleGroup && (
                            <Form.Group controlId="exerciseSelect">
                            <Form.Label>Select Exercise</Form.Label>
                            <Form.Control as="select" value={selectedExercise} onChange={handleExerciseChange}>
                                <option value="">-- Select Exercise --</option>
                                {muscleGroups[selectedMuscleGroup].map((exercise) => (
                                <option key={exercise} value={exercise}>{exercise}</option>
                                ))}
                            </Form.Control>
                            </Form.Group>
                        )}

                        {selectedExercise && (
                                <Form.Group controlId="caloriesInput">
                                <Form.Label>Calories Burnt</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter calories burnt"
                                    value={caloriesBurnt}
                                    onChange={handleCaloriesChange}
                                />
                                </Form.Group>

                                
                        )}

                        {caloriesBurnt && (
                            <Button variant="primary" type="submit">
                            Submit
                            </Button>
                        )}

                    
                        </Form>
                    </Card.Body>
                </Card> 

                
            </div>
        </Container>
    );
}


export default Fitness;
