import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container, Toast } from 'react-bootstrap';
import { useAuth } from '../providers';
import { useNavigate } from 'react-router-dom';
import { fetchProfileDetails } from './ProfileDetails';

function ProfileEdit() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [myAddress, setMyAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [myHospital, setMyHospital] = useState('');
    const [myDateOfBirth, setDateOfBirth] = useState('');
    const [gender, setGender] = useState('');
    const [initialState, setInitialState] = useState({});
    const [errors, setErrors] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');
    const navigate = useNavigate();
    const auth = useAuth();

    const addProfile = async () => {
        if (!firstName || !lastName || !email || !phoneNumber) {
            setErrors(true);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: userId,
                firstName: firstName,
                lastName: lastName,
                myAddress: myAddress,
                email: email,
                phoneNumber: phoneNumber,
                myHospital: myHospital,
                myDateOfBirth: myDateOfBirth,
                gender: gender
            })
        };

        const response = await fetch('https://medharbor-backend.onrender.com/profile/createProfile', requestOptions);

        const data = await response.json();
        alert(data.message); // Display message in an alert box
        fetchProfileDetails();

        setToastMessage('Profile created successfully!');
        setToastVariant('success');
        setShowToast(true);
        setTimeout(() => {
            navigate('/ProfileDetails');
        }, 1500);
    };
const userId = auth.user.id;

    useEffect(() => {
    console.log("User ID in useEffect:", userId);
        
        if (userId) {
            const loadProfile = async () => {
                console.log("User ID in loadProfile:", userId);
                try {
                    const data = fetchProfileDetails(userId);
                    console.log("Data", data);
                    setFirstName(data.firstName || '');
                    setLastName(data.lastName || '');
                    setEmail(data.email || '');
                    setMyAddress(data.myAddress || '');
                    setPhoneNumber(data.phoneNumber || '');
                    setMyHospital(data.myHospital || '');
                    setDateOfBirth(data.myDateOfBirth || '');
                    setGender(data.gender || '');
                    setInitialState({
                        firstName: data.firstName || '',
                        lastName: data.lastName || '',
                        email: data.email || '',
                        myAddress: data.myAddress || '',
                        phoneNumber: data.phoneNumber || '',
                        myHospital: data.myHospital || '',
                        myDateOfBirth: data.myDateOfBirth || '',
                        gender: data.gender || '',
                    });
                } catch (error) {
                    console.error("Failed to load profile:", error);
                }
            };
            loadProfile();
        }
    }, [userId]);

    const handleCancel = () => {
        navigate('/ProfileDetails');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            firstName === initialState.firstName &&
            lastName === initialState.lastName &&
            myAddress === initialState.myAddress &&
            email === initialState.email &&
            phoneNumber === initialState.phoneNumber &&
            myHospital === initialState.myHospital &&
            myDateOfBirth === initialState.myDateOfBirth &&
            gender === initialState.gender
        ) {
            setToastMessage('Please update any one of the fields');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }

        const updatedDetails = {
            firstName,
            lastName,
            myAddress,
            email,
            phoneNumber,
            myHospital,
            myDateOfBirth,
            gender
        };


        try {
            console.log('Sending request to update profile with details:', updatedDetails);

            const response = await fetch(`https://medharbor-backend.onrender.com/profile/${userId}/update`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedDetails)
            });

            const data = await response.json();
            console.log('Response from server:', data);

            if (response.status === 404) {
                console.warn('Bad request detected. Creating a new profile instead.');
                addProfile();
            } else {
                setToastMessage('Profile updated successfully!');
                setToastVariant('success');
                setShowToast(true);
                setTimeout(() => {
                    navigate('/ProfileDetails');
                }, 1500);
            }
        } catch (error) {
            console.error('An error occurred:', error.message);
            setToastMessage('Failed to update profile. Please try again.');
            setToastVariant('danger');
            setShowToast(true);
        }
    };

    return (
        <Container className='p-3'>
            <h1>Update Profile</h1>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="formFirstName" className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                isInvalid={!!errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.firstName}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formLastName" className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                isInvalid={!!errors.lastName}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.lastName}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formPhNo" className="mb-3">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your Phone Number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                isInvalid={!!errors.phoneNumber}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.phoneNumber}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formHospital" className="mb-3">
                            <Form.Label>Hospital</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your hospital"
                                value={myHospital}
                                onChange={(e) => setMyHospital(e.target.value)}
                                isInvalid={!!errors.myHospital}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.myHospital}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formAddress" className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your address"
                                value={myAddress}
                                onChange={(e) => setMyAddress(e.target.value)}
                                isInvalid={!!errors.myAddress}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.myAddress}
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formDOB" className="mb-3">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                                type="date"
                                value={myDateOfBirth}
                                onChange={(e) => setDateOfBirth(e.target.value)}
                                isInvalid={!!errors.myDateOfBirth}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.myDateOfBirth}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formGender" className="mb-3">
                            <Form.Label>Gender</Form.Label>
                            <Form.Control
                                as="select"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                isInvalid={!!errors.gender}
                            >
                                <option value="">Select your gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.gender}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" className="my-2" type="submit" onClick={handleSubmit}>
                    Submit
                </Button>
                <Button variant="secondary" className="my-2" style={{ marginLeft: '10px' }} type="button" onClick={handleCancel}>
                    Cancel
                </Button>
            </Form>

            <Toast show={showToast} onClose={() => setShowToast(false)} style={{ position: 'fixed', top: '10%', right: '10%' }}>
                <Toast.Header className={`bg-${toastVariant} text-white`}>
                    <strong className="mr-auto">Profile Update</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </Container>
    );
}

export default ProfileEdit;