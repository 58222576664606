import React, { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import '../styles/symptomTracker.css';
import '../styles/customDatePickerWidth.css';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../providers';
import toast from 'react-hot-toast';
import Slider from '../components/slider'
import ChipInput from './chipsInput';

const SymptomMain = ({show, handleCloseSymptomPopup}) => {
    const auth = useAuth();
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [hovered, setHovered] = useState(false);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [painLevel, setPainLevel] = useState(0);
    const [isChronic, setIsChronic] = useState(false);
    const [additional, setAdditional] = useState('');

    useEffect(() => {
        if (show) {
            setName('');
            setDesc('');
            setAdditional('');
            setDate(null);
            setTime(null);
            setErrors({});
            setTouched({});
            setPainLevel(0);
            setIsChronic(false);
            
        }
    }, [show]);

    
    const handleAdditionalChange = (e) => {
        // const { name, value } = e.target;
        // if (name === 'additional') {
        //     setAdditional(value);
        // }
        setAdditional(e.target.value);
    };
    
    const handleIsChronic = (isChronic) =>{
        setIsChronic(isChronic);
    }

    const handlePainLevel = (painLevel) =>{
         setPainLevel(painLevel);
    }

    const handleDateChange = (date) => {
        setDate(date);
        validateField('date', date);
    };

    const handleTimeChange = (time) => {
        setTime(time);
        validateField('time', time);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'description') {
            setDesc(value);
        }
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let fieldErrors = { ...errors };
        let isTouched = { ...touched };
        isTouched[name] = true;

        switch (name) {
            case 'name':
                if (!value) {
                    fieldErrors[name] = 'Symptom name is required';
                } else if (!/^[A-Za-z\s]+$/.test(value)) {
                    fieldErrors[name] =
                        'Symptom name must contain only alphabets';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'description':
                if (!value) {
                    fieldErrors[name] = 'Description is required';
                } else if (! /^[A-Za-z0-9\s:,]+$/.test(value)) {
                    fieldErrors[name] =
                        'Description must not contain special characters';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'date':
                if (!value) {
                    fieldErrors[name] = 'Date is required';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'time':
                if (!value) {
                    fieldErrors[name] = 'Time is required';
                } else {
                    delete fieldErrors[name];
                }
                break;
            default:
                break;
        }

        setErrors(fieldErrors);
        setTouched(isTouched);
    };

    const isDisabled = () => {
        if (Object.keys(errors).length > 0) return true;
        if (!name || !desc || !date || !time) return true;
        return false;
    };

    const saveSymptom = async () => {
        if (auth.user == null) {
            toast.error('Please login first');
        } else {
            const url = 'https://medharbor-backend.onrender.com/symptoms'; // change this to og url - 'https://medharbor-backend.onrender.com/symptoms'
            const symptom = {
                symptomName: name,
                description: desc,
                additional: additional,
                creator: auth.user?.id,
                date: date,
                time: time,
                painLevel:painLevel,
                isChronic: isChronic,
            };

            console.log('symptom data', symptom);
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(symptom),
            });
            try {
                if (!res.ok) {
                    throw new Error('Failed to post the symptom');
                }
                console.log('add symptom posted successfully', res);
                const response = await res.json();
                handleCloseSymptomPopup();
                console.log('new post symptom', response);
                return toast.success('Symptom has been added successfully!');
            } catch (error) {
                console.error('Error posting symptom', error.message);
                return toast.error(error.message);
            }
        }
    };

    return (
        <div>
            <Modal show = {show} onHide={handleCloseSymptomPopup} >
                <Modal.Header closeButton>
                    <Modal.Title>Add a new symptom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Symptom</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={name}
                                onChange={handleInputChange}
                                onBlur={(e) =>
                                    validateField(e.target.name, e.target.value)
                                }
                                placeholder="Add symptom name"
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={desc}
                                onChange={handleInputChange}
                                onBlur={(e) =>
                                    validateField(e.target.name, e.target.value)
                                }
                                placeholder="Describe how you are feeling"
                                isInvalid={!!errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Additional Symptoms</Form.Label>
                            <Form.Control
                                type="text"
                                name="additional"
                                value={additional}
                                onChange={handleAdditionalChange}
                                // onBlur={(e) =>
                                //     validateField(e.target.name, e.target.value)
                                // }
                                placeholder="Add any additional symptoms"
                                //isInvalid={!!errors.description}
                            />
                            
                        </Form.Group>
                    
                        {/* <Form.Group style={{ marginTop: 10 }}>
                        <ChipInput></ChipInput>
                        </Form.Group> */}
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Date</Form.Label>
                            <br />
                            <div className="customDatePickerWidth">
                                <DatePicker
                                    className={`customDatePickerWidth ${
                                        errors.date ? 'is-invalid' : ''
                                    }`}
                                    selected={date}
                                    onChange={handleDateChange}
                                    onBlur={() => validateField('date', date)}
                                    placeholderText="Select a Date..."
                                    dateFormat="MM/dd/yyyy"
                                />
                                <div className="invalid-feedback">
                                    {errors.date}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Time</Form.Label>
                            <br />
                            <div className="customDatePickerWidth">
                                <TimePicker
                                    className={`customDatePickerWidth ${
                                        errors.time ? 'is-invalid' : ''
                                    }`}
                                    disableClock={true}
                                    format="hh:mm a"
                                    value={time}
                                    onChange={handleTimeChange}
                                    onBlur={() => validateField('time', time)}
                                    placeholder="Select time"
                                />
                                <div className="invalid-feedback">
                                    {errors.time}
                                </div>
                            </div>
                           <Slider onPainLevelChanged={handlePainLevel}
                                    onChronicChanged={handleIsChronic}></Slider>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseSymptomPopup}>Cancel</Button>
                    <Button onClick={saveSymptom} disabled={isDisabled()}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SymptomMain;
