import React, { useState } from 'react';
import '../styles/Signin.css';
import email_icon from '../Assets/email.png';
import password_icon from '../Assets/password.png';
import logo from '../Assets/icons8-medical-50.png';
import apple from '../Assets/apple.png';
import google from '../Assets/google_resized.png';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuth } from '../providers';

const Signin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const auth = useAuth();
    const navigate = useNavigate();

    const signUserIn = async () => {
        if (!email || !password) {
            return toast.error('Please enter email and password');
        }

        const data = await auth.login(email, password);

        if (!data.success) {
            return toast.error(data.message);
        }

        return toast.success('Logged in successfully!');
    };

    if (auth.user) {
        return navigate('/');
    }

    return (
        <div className="siginin-container">
            <div className="siginin-company-name">
                <img src={logo} alt="Med Harbor" style={{width: '40px'}} />
                <header>Med Harbor</header>
            </div>
            <div className="siginin-inputs">
                <div className="siginin-input">
                    <img src={email_icon} alt="" style={{width: '22px'}} />
                    <input
                        type="username"
                        placeholder="Email or Med Harbor ID"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="siginin-input">
                    <img src={password_icon} alt="" style={{width: '22px'}} />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            </div>

            <div className="siginin-submit-container">
                <div className="siginin-submit" onClick={signUserIn}>
                    Sign in
                </div>
            </div>

            <div className="siginin-forgot-password">
                <span>
                    <Link to="/reset" className="siginin-signup-external-link">
                        {' '}
                        Forgot password?
                    </Link>
                </span>
            </div>

            <div className="siginin-no-account">
                {' '}
                Don't have an account?
                <span>
                    <Link to="/signup" className="siginin-signup-external-link">
                        {' '}
                        Sign up
                    </Link>
                </span>
            </div>

            {/* <div className="siginin-divider">
                ━━━━━━ &nbsp; OR &nbsp; ━━━━━━
            </div>

            <div className="siginin-apple-sign-in">
                <img
                    src={apple}
                    alt=""
                    style={{ width: '3%', height: '10%' }}
                />
                <span>
                    <a
                        href="https://appleid.apple.com/sign-in"
                        target="_blank"
                        className="siginin-apple-external-link"
                        rel="noreferrer"
                    >
                        Continue with Apple{' '}
                    </a>
                </span>
            </div>

            <div className="siginin-google-sign-in">
                <img
                    src={google}
                    alt=""
                    style={{ width: '3%', height: '10%' }}
                />
                <span>
                    <a
                        href="https://mail.google.com/"
                        target="_blank"
                        className="siginin-google-external-link"
                        rel="noreferrer"
                    >
                        Continue with Google{' '}
                    </a>
                </span>
            </div> */}

            <div className="siginin-terms-and-policy">
                <span>
                    <a
                        href="https://www.apple.com/legal/internet-services/terms/site.html"
                        target="_blank"
                        className="siginin-terms-of-use"
                        rel="noreferrer"
                    >
                        Terms of Use
                    </a>
                </span>
                &nbsp; | &nbsp;
                <span>
                    <a
                        href="https://www.apple.com/legal/privacy/"
                        target="_blank"
                        className="siginin-privacy-policy"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                </span>
            </div>
        </div>
    );
};

export default Signin;
