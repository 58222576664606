import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap'

function SleepRangeSlider({ sleep, date, onSubmit, disabled}) {
    const [sleepAmount, setSleepAmount] = useState(sleep);
    
    useEffect(()=> {
        setSleepAmount(sleep);
    },[sleep])

    const handleSliderChange = (event) => {
        setSleepAmount(event.target.value)
    };

    const handleSubmit = (event) => { 
        event.preventDefault();
        if (onSubmit) {
            onSubmit(sleepAmount)
        }
    };

    return(
        <Form onSubmit={handleSubmit} className="full-width-form">
            <div className='slider-container'>
                <Form.Group controlId='formSleepAmount' className="form-group-full-width">
                    <Form.Label>Sleep Amount: {sleepAmount} H</Form.Label>
                    <Form.Control
                        type="range"
                        min="0"
                        max="12"
                        step="0.5"
                        value={sleepAmount}
                        onChange={handleSliderChange}
                        disabled={disabled}
                    />
                </Form.Group>
                <div className="button-container">
                    <Button variant="primary" type="submit" disabled={disabled}>
                        Add Sleep
                    </Button> 
                </div>
            </div>
        </Form>
    )
}

export default SleepRangeSlider;