import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CaloriesBurnProgressBar = ({ totalCaloriesBurn, goalCaloriesBurn, disabled }) => {
  const percentage = goalCaloriesBurn > 0 ? (totalCaloriesBurn / goalCaloriesBurn) * 100 : 0;
  const progressText = totalCaloriesBurn > goalCaloriesBurn
    ? `You have exceeded the goal by ${totalCaloriesBurn - goalCaloriesBurn} Cal`
    : `You need ${goalCaloriesBurn - totalCaloriesBurn} Cal more to reach the goal`;

  return (
    <div>
      <h5 className="caloriesburn">Calories Burned Progress</h5>
      <ProgressBar
        striped
        animated
        variant="caloriesburn"
        now={percentage}
        label={`${percentage.toFixed(0)}%`}
        disabled={disabled}
      />
      <p>{progressText}</p>
    </div>
  );
};


export default CaloriesBurnProgressBar;