import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const GoalInputs = ({ enabled, onWaterGoalChange, onCaloriesConsumeGoalChange, onCaloriesBurnGoalChange, waterGoal, caloriesConsumeGoal, caloriesBurnGoal }) => (
    <div>
        <Row className="mt-3">
            <Col>
                <Form.Group controlId="formWaterGoal">
                    <Form.Label>Water Goal (L)</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter water goal"
                        value={waterGoal}
                        onChange={(e) => onWaterGoalChange(e.target.value)}
                        disabled={!enabled}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="formCaloriesConsumeGoal">
                    <Form.Label>Calories to Consume (Cal)</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter calories to consume goal"
                        value={caloriesConsumeGoal}
                        onChange={(e) => onCaloriesConsumeGoalChange(e.target.value)}
                        disabled={!enabled}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="formCaloriesBurnGoal">
                    <Form.Label>Calories to Burn (Cal)</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter calories to burn goal"
                        value={caloriesBurnGoal}
                        onChange={(e) => onCaloriesBurnGoalChange(e.target.value)}
                        disabled={!enabled}
                    />
                </Form.Group>
            </Col>
        </Row>
    </div>
);

export default GoalInputs;
