import React from 'react';
import { ProgressBar } from 'react-bootstrap';
 
function WaterProgressBar({ currentWater, goalWater }) {
    const progress = goalWater>0 ? (currentWater / goalWater) * 100 : 0;
    const formattedProgress = progress % 1 === 0 ? progress : parseFloat(progress.toFixed(2));
    const progressText = currentWater > goalWater
        ? `You have exceeded the goal by ${currentWater - goalWater} L`
        : `You need ${goalWater - currentWater} L more to reach the goal`;

    return (
        <div>
            <h5 class="waterconsume">Water Intake Progress</h5>
            <ProgressBar now={formattedProgress} label={`${formattedProgress}%`} />
            <p>{progressText}</p>
        </div>
    );
}

export default WaterProgressBar;
