import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap'

function StressRangeSlider({ stress, date, onSubmit, disabled}) {
    const [stressAmount, setStressAmount] = useState(stress);

    useEffect(()=> {
        setStressAmount(stress);
    },[stress])

    const handleSliderChange = (event) => {
        setStressAmount(event.target.value)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (onSubmit) {
            onSubmit(stressAmount)
        }
    };

    return(
        <Form onSubmit={handleSubmit} className="full-width-form">
            <div className='slider-container'>
                <Form.Group controlId='formStressAmount' className="form-group-full-width">
                    <Form.Label>Stress Level: {stressAmount} (0-10)</Form.Label>
                    <Form.Control
                        type="range"
                        min="0"
                        max="10"
                        step="1"
                        value={stressAmount}
                        onChange={handleSliderChange}
                        disabled={disabled}
                    />
                </Form.Group>
                <div className="button-container">
                    <Button variant="primary" type="submit" disabled={disabled}>
                        Add Stress
                    </Button> 
                </div>
            </div>
        </Form>
    )
}

export default StressRangeSlider;