import React, { useState, useRef, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../styles/Signin.css';
import logo from '../Assets/icons8-medical-50.png';
import toast from 'react-hot-toast';

function Otp() {
    //const API_URLS = "https://medharbor-wd6d.onrender.com/resetPassword";
    const API_URLS = "https://medharbor-backend.onrender.com/resetPassword";

    const [inputOtp, setInputOtp] = useState(['', '', '', '']);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];
    const [disable, setDisable] = useState(true);
    const [timerCount, setTimer] = React.useState(30);
    const location = useLocation();
    const navigate = useNavigate();
    
    const { email, otp } = location.state;
    console.log('Context:', otp, email);

    const handleChange = (index, e) => {
        const digit = e.target.value.replace(/\D/, '');
        const newInputOtp = [...inputOtp];
        newInputOtp[index] = digit;
        setInputOtp(newInputOtp);

        // Move to the next input
        if (digit != '' && (e.target.value && index < inputRefs.length - 1)) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleKeyDown = (index, e) => {
        // Move to the previous input on backspace
        if (e.key === 'Backspace' && index > 0 && !inputOtp[index]) {
            inputRefs[index - 1].current.focus();
        }
    };

    const submitOtp = () =>{
        if(otp == inputOtp.join('')){
            navigate('/newpassword', {state:{email}})
        }
        else{
            toast.error("Invalid OTP")
        }
    }

    const resendOTP = async() => {
        setDisable(true);
        setTimer(30);

        const req = await fetch(API_URLS, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, otp: otp}),
        });

        console.log(req);
    }

    useEffect(() => {
        let interval = setInterval(() => {
          setTimer((lastTimerCount) => {
            lastTimerCount <= 1 && clearInterval(interval);
            if (lastTimerCount <= 1) setDisable(false);
            if (lastTimerCount <= 0) return lastTimerCount;
            return lastTimerCount - 1;
          });
        }, 1000); //each count lasts for a second
        //cleanup the interval on complete
        return () => clearInterval(interval);
      }, [disable]);

    return (
        <Form className="siginin-container">
            <div className="siginin-company-name">
                <img src={logo} alt="Med Harbor" style={{width: '40px'}} />
                <header>Med Harbor</header>
            </div>
            <h3 style={{marginTop: 40, textAlign: 'center'}}>Email verification</h3>
            <div style={{textAlign:'center', color:'grey'}}>
                Enter the code sent to email {email}
            </div>
            <Row className="justify-content-center" style={{marginTop: 80}}>
                {inputOtp.map((digit, index) => (
                    <Col key={index} xs={2}>
                        <Form.Control
                            type="text"
                            pattern="[0-9]*"
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            ref={inputRefs[index]}
                            className='textbox'
                            style={{fontSize: '26px', fontWeight: 300}}
                        />
                    </Col>
                ))}
            </Row>

            <div className="siginin-submit-container"  style={{marginTop: 80}}>
                <div className="siginin-submit" onClick={submitOtp}>
                    Verify
                </div>
            </div>

            <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                    <p>Didn't recieve code?{" "}
                    <a
                      className="flex flex-row items-center"
                      style={{
                        color: disable ? "gray" : "blue",
                        cursor: disable ? "none" : "pointer",
                        textDecorationLine: disable ? "none" : "underline",
                      }}
                      onClick={resendOTP}
                    >
                      {disable ? `Resend OTP in ${timerCount}s` : "Resend OTP"}
                    </a>
                    </p>
                  </div>
        </Form>
    );
}

export default Otp;