import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { jwtDecode } from 'jwt-decode';

import {
    API_URLS,
    getItemFromLocalStorage,
    LOCALSTORAGE_TOKEN_KEY,
    removeItemFromLocalStorage,
    setItemInLocalStorage,
} from '../utils';


const AuthContext = createContext(null);

const useProvideAuth = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const localStorageKey = LOCALSTORAGE_TOKEN_KEY;

    // Get user from the token
    useEffect(() => {
        const userToken = getItemFromLocalStorage(localStorageKey);

        if (userToken) {
            const user = jwtDecode(userToken);
            setUser(user);
        }
        setLoading(false);
    }, [localStorageKey]);

    // Login user
    const login = async (email, password) => {
        const url = API_URLS.signin();

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();

            if (response.ok) {
                setUser(data.data.user);
                setItemInLocalStorage(localStorageKey, data.data.token);
                return { success: true, data: data };
            } else {
                throw Error(data.message);
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    //Update password
    const update_password = async (email, password) => {
        const url = API_URLS.updatePassword();
        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();

            if (response.ok) {
                return { success: true, data: data };
            } else {
                throw Error(data.message);
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    // Signup user
    const signup = async (
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        userType,
        subscribeCheckbox
    ) => {
        const url = API_URLS.signup();
        const body = JSON.stringify({
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            userType,
            subscribeCheckbox
        });
        
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body,
            });
            const data = await response.json();

            if (response.ok) {
                setUser(data.data.user);
                setItemInLocalStorage(localStorageKey, data.data.token);
                return { success: true, data: data };
            } else {
                throw Error(data.message);
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    // Signout user
    const signout = () => {
        removeItemFromLocalStorage(localStorageKey);
        setUser(null);
    };

    return {
        user,
        login,
        signout,
        signup,
        update_password,
        loading,
    };
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const auth = useProvideAuth();

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
