import React, { useState } from 'react';
import '../styles/Signin.css';
import email_icon from '../Assets/email.png';
import password_icon from '../Assets/password.png';
import logo from '../Assets/icons8-medical-50.png';
import apple from '../Assets/apple.png';
import google from '../Assets/google_resized.png';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuth } from '../providers';

export default function LoginPopup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const auth = useAuth();
    const navigate = useNavigate();

    const signUserIn = async () => {
        if (!email || !password) {
            return toast.error('Please enter email and password');
        }

        const data = await auth.login(email, password);

        if (!data.success) {
            return toast.error(data.message);
        }

        return toast.success('Logged in successfully!');
    };

    return (
        <div
            style={{
                position: 'absolute',
                top: 70,
                bottom: 0,
                left: 0,
                right: 0,
                backdropFilter: 'blur(1.5px)',
            }}
        >
            <div
                className="siginin-container"
                style={{
                    marginTop: '10px',
                    border: '1px solid #e1e1e1',
                    width: 500,
                }}
            >
                <div className="siginin-company-name">
                    <img src={logo} alt="Med Harbor"  style={{width:'40px'}}/>
                    <header>Med Harbor</header>
                                    
                </div>
                <div className="siginin-inputs">
                    <div className="siginin-input">
                        <img src={email_icon} alt=""  style={{width:'22px'}}/>
                        <input
                            type="username"
                            placeholder="Email or Med Harbor ID"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="siginin-input">
                        <img src={password_icon} alt="" style={{width:'22px'}}/>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="siginin-submit-container">
                    <div className="siginin-submit" onClick={signUserIn}>
                        Sign in
                    </div>
                </div>
                <div className="siginin-forgot-password">
                    <span>
                        <a
                            href="https://www.linkedin.com/uas/request-password-reset?trk=homepage-basic_forgot_password&original_referer=https%3A%2F%2Fwww.linkedin.com%2F"
                            target="_blank"
                            className="siginin-forgot-password-external-link"
                            rel="noreferrer"
                        >
                            {' '}
                            Forgot Password?
                        </a>
                    </span>
                </div>
                {/* <div className="siginin-no-account">
                    Don't have an account?
                    <span>
                        <Link
                            to="/signup"
                            className="siginin-signup-external-link"
                        >
                            {' '}
                            Sign up
                        </Link>
                    </span>
                </div> */}
            </div>
        </div>
    );
}
