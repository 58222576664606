import classes from '../styles/gallerySection.module.css';
import image2 from '../Assets/carousel-2.jpeg';
import image4 from '../Assets/carousel-4.jpg';
import gallerImg4 from '../Assets/gallery-4.jpg';
import { useState } from 'react';


function GalleryComponent() {
    const [show, setShow] = useState(false);
  return (
      <section className={classes.gallary}>
          <div
              className={`${classes.section__container} ${classes.gallary__container}`}
          >
              <div className={classes.image__gallary}>
                  <div className={classes.gallary__col}>
                      <img
                          src={gallerImg4}
                          alt="gallary"
                          style={{ width: '270px' }}
                      />
                  </div>
                  <div className={classes.gallary__col}>
                      <img
                          src={image4}
                          alt="gallary"
                          style={{ width: '270px' }}
                      />
                      <img
                          src={image2}
                          alt="gallary"
                          style={{ width: '270px' }}
                      />
                  </div>
              </div>
              <div className={classes.gallary__content}>
                  <div>
                      <h2 className={classes.section__title}>
                          Keep track of your medical records
                      </h2>
                      <p className={classes.section__subtitle}>
                          Med Harbor tailors your personal health records to
                          track upcoming and past appointments, insurance and
                          billing details, and previous medical information.
                      </p>
                  </div>
              </div>
          </div>
      </section>
  );
}

export default GalleryComponent;
