import React, { useState } from 'react';
import '../styles/Signin.css';
import password_icon from '../Assets/password.png';
import logo from '../Assets/icons8-medical-50.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../providers';
import toast from 'react-hot-toast';

function NewPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const {email} = location.state;
    
    const changePassword = async() => {
        if(!password){
            toast.error("Password cannot be empty");
        }
        else if(!(password === confirmPassword)){
            toast.error("Passwords do not match");
        }
        else{
            const data = await auth.update_password(email, password);

            if (!data.success) {
                return toast.error(data.message);
            }
            toast.success("Password updated");

            navigate("/signin");
        }
    }

    return (
        <div>
            <div className="siginin-container">
                <div className="siginin-company-name">
                    <img src={logo} alt="Med Harbor" style={{width: '40px'}} />
                    <header>Med Harbor</header>
                </div>

                <h3 style={{marginTop: 50, textAlign: 'center'}}>Reset password</h3>
                
                <div className="siginin-inputs">
                    <div className="siginin-input">
                        <img src={password_icon} alt="" style={{width: '22px'}} />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="siginin-input">
                        <img src={password_icon} alt="" style={{width: '22px'}} />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                </div>

                <div className="siginin-submit-container">
                    <div className="siginin-submit" onClick={changePassword}>
                        Change password
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewPassword;