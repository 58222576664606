// App.js
import React, {useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    MedicationPage,
    Home,
    Signin,
    Newsletter,
    MyRecords,
    Signup,
    NewsletterForm,
} from './pages';
import { Navbar } from './components';
import SymptomTracker from './pages/SymptomTracker';
import Otp from './pages/Otp';
import { Fitness } from './pages';
import Reset from './pages/Reset';
import NewPassword from './pages/NewPassword';
import ProfileEdit from './pages/ProfileEdit';
import ProfileDetails from './pages/ProfileDetails';
import { UserProvider } from './components/UserContext';

function App() {
    useEffect(() => {
        // Function to update the favicon
        const updateFavicon = (isDarkMode) => {
            const favicon = document.getElementById('favicon');
            if (isDarkMode) {
                favicon.href = process.env.PUBLIC_URL + 'favicon.ico';
            } else {
                favicon.href = process.env.PUBLIC_URL + '/faviconBlack.png';
            }
        };

        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)'); //checking for dark mode
        updateFavicon(mediaQuery.matches);

        mediaQuery.addEventListener('change', (e) => updateFavicon(e.matches)); //looking for change of theme

        return () => mediaQuery.removeEventListener('change', (e) => updateFavicon(e.matches)); //cleanup
    }, []);

    return (
        <UserProvider>
            <div>
                <Navbar />
            </div>
            <div className= 'main-container' style={{marginTop: '74px', padding: '0px'}}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/medication" element={<MedicationPage />} />
                    <Route path="/newsletter" element={<Newsletter />} />
                    <Route path="/symptomtracker" element={<SymptomTracker />} />
                    <Route path="/myrecords" element={<MyRecords />} />
                    <Route path="/fitness" element={<Fitness />} />
                    <Route path="/signin" element={<Signin />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/NewsletterForm" element={<NewsletterForm />} />
                    <Route path="/reset" element={<Reset />} />
                    <Route path="/otp" element={<Otp />} />
                    <Route path="/newpassword" element={<NewPassword />} />
                    <Route path="/ProfileUpdate" element={<ProfileEdit />} />
                    <Route path="/ProfileDetails" element={<ProfileDetails />} />
                    
                    {/* <Route path="*" element={<Page404 />} /> */}
                </Routes>
            </div>
        </UserProvider>
    );
}

export default App;
