// DateInput.js

import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';

const DateInput = ({ onDateChange }) => {
  return (
    <InputGroup className='pb-3' controlId='date'>
      <InputGroupText>Date</InputGroupText>
      <Form.Control 
        type="date" 
        onChange={(e) => onDateChange(e.target.value)} 
      />
    </InputGroup>
  );
};

export default DateInput;
